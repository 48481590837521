<div class="table_container mat-elevation-z8">
  <div class="table-paginator">
    <div class="table-mat-table">
      <table mat-table class="table" [dataSource]="dataSource" matSort>
    
        <!-- Dynamic content -->
        <ng-container *ngFor="let label of columns" matColumnDef="{{ label.name }}">
          <mat-header-cell class="column" *matHeaderCellDef mat-sort-header [ngClass]="{'small-cell': largeTable}">
            {{label.displayName}}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="{'small-cell': largeTable}">
            <!-- TODO TRY NGSWITCH -->
            <div *ngIf="label.type === columnTypes.Date; else elseTemplate">
              {{ element[label.name] | date: 'dd/MM/yyyy' }}
            </div>
            <ng-template #elseTemplate>
              {{element[label.name]}}
            </ng-template>
          </mat-cell>
        </ng-container>
    
        <!-- Actions -->
        <ng-container matColumnDef="action">
          <mat-header-cell class="columna_final_header" *matHeaderCellDef [ngClass]="{'small-cell': largeTable}">Acciones</mat-header-cell>
          <mat-cell *matCellDef="let row" class="columna_final" [ngClass]="{'small-cell': largeTable}">
            <button *ngIf="row.estado === 'Pendiente' || row.estado === 'Denegado' || row.estado === 'Anulado'" mat-icon-button (click)="approveRow(row)" matTooltip="Aprobar solicitud">
              <mat-icon class="material-icons">
                check
              </mat-icon>
            </button>
            <button *ngIf="row.estado === 'Pendiente'" mat-icon-button (click)="rejectRow(row)" matTooltip="Denegar solicitud">
              <mat-icon class="material-icons">
                close
              </mat-icon>
            </button>
            <button *ngIf="row.estado === 'Aprobado'" mat-icon-button (click)="cancelRow(row)" matTooltip="Anular solicitud">
              <mat-icon class="material-icons">
                block
              </mat-icon>
            </button>
          </mat-cell>
        </ng-container>
    
        <!-- Filters -->
        <ng-container *ngIf="showFilters">
          <ng-container *ngFor="let headerFilter of headersFilters;let i = index"
            matColumnDef="{{headerFilter.displayName}}">
            <mat-header-cell *matHeaderCellDef [ngClass]="{'small-cell': largeTable}">
              <mat-form-field *ngIf="headersFilters.length - 1 !== i && headerFilter.type === columnTypes.Standard"
                class="filter mat-input-search-wrapper" floatLabel="never">
                <input class="wordInput" matInput [formControl]="tableFormControls[i]" autocomplete="off">
              </mat-form-field>
              <!-- TODO-HANDLE-DATE-FILTER -->
              <mat-form-field  *ngIf="headersFilters.length - 1 !== i  && headerFilter.type === columnTypes.Date"
                  class="filter mat-input-search-wrapper" floatLabel="never">
                  <input class="wordInput" readonly matInput [matDatepicker]="picker" [formControl]="tableFormControls[i]" autocomplete="off">
                  <mat-datepicker-toggle class="toggle-suffix" matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker-toggle class="toggle-suffix" matSuffix (click)="clearStartDate(tableFormControls[i])">
                    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                  <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
              </mat-form-field>
            </mat-header-cell>
          </ng-container>
        </ng-container>
    
        <!-- Content -->
        <mat-header-row *matHeaderRowDef="columnLabels" class="table_header"></mat-header-row>
        <ng-container *ngIf="showFilters">
          <mat-header-row class="no-default-height" *matHeaderRowDef="headersFilterName"></mat-header-row>
        </ng-container>
        <mat-row *matRowDef="let row; columns: columnLabels;"></mat-row>
      </table>
    </div>
    <mat-paginator *ngIf="showPaginator" [pageSizeOptions]="[5, 10, 20]" [pageSize]="5" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>