import { MediaMatcher } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';

export interface MenuItems {
  icon?: string;
  label: string;
  routes: string[];
  clicked?: boolean;
  menuItems?: MenuItems[];
}

@Component({
  selector: 'ekiba-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnDestroy {
  private _menuItems: MenuItems[];
  /**
   * Each of the redirections
   */
  @Input()
  public set menuItems(newValue) {
    if (newValue === this._menuItems || newValue.length === 0) {
      return;
    }
    this._menuItems = newValue.map((value) => {
      return {
        ...value,
        clicked: false,
      };
    });
  }
  public get menuItems(): MenuItems[] {
    return this._menuItems;
  }

  /**
   * Route to go to home
   */
  @Input()
  public homeRoute: string;

  @Input()
  public name: string;

  @Input()
  public role: string;

  @Output()
  public requestLogout = new EventEmitter<void>();

  public currentDate: number = new Date().getTime();
  public mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  public constructor(
    public readonly configService: ConfigService,
    private readonly _activateRoute: ActivatedRoute,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _location: Location,
    private readonly _media: MediaMatcher,
    private readonly _router: Router
  ) {
    this.mobileQuery = this._media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this._changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  public ngOnDestroy(): void {
    this._menuItems = undefined;
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  public getRoutes(child: MenuItems): string {
    let routes = '/';
    if (child.routes) {
      routes = child.routes.toString().replace(/,/g, '/');
    }
    return routes.toString();
  }

  public handleRedirect(parent: MenuItems, child: MenuItems): void {
    this.menuItems.forEach((item) => {
      item.clicked = false;
      if (item.menuItems) {
        item.menuItems.forEach((child) => (child.clicked = false));
      }
    });
    const indexUpdated = this.menuItems.indexOf(parent);
    this.menuItems[indexUpdated].clicked = true;
    const indexChild = this.menuItems[indexUpdated].menuItems.indexOf(child);
    this.menuItems[indexUpdated].menuItems[indexChild].clicked = true;
    this.redirectTo(child.routes);
  }

  public redirectTo(routes: string[]): void {
    this._router.navigate(['..', ...routes], {
      relativeTo: this._activateRoute,
    });
  }

  public return(): void {
    this._location.back();
  }

  public logOut(): void {
    this.requestLogout.emit();
  }
}
