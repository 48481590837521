import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import { ConfigService } from 'src/app/shared/services/config.service';

export interface CalendarInput {
  pUserId: string;
  pInicio: string;
  pFin: string;
  pTipoCalendario?: string;
}
export interface ResponseCall {
  '@odata.context': string;
  value: string;
}

export interface VacationsCall {
  CodigoUnico: { caption: string; value: string };
  Description: { caption: string; value: string };
  'Ending Date': { caption: string; value: string };
  'Num Dias Nat Hab': { caption: string; value: string };
  Recurso: { caption: string; value: string };
  'Starting Date': { caption: string; value: string };
  Tipo: { caption: string; value: string };
}

@Injectable({
  providedIn: 'root',
})
export class WorkCalendarCall {
  private readonly _getWorkCalendar = this._configService.url('urlCalendar');
  private readonly _getHolidaysCalendar = this._configService.url(
    'urlHolidaysCalendar'
  );
  private readonly _getVacationsCalendar = this._configService.url(
    'urlVacationsCalendar'
  );

  public constructor(
    private readonly _authStorageService: AuthStorageService,
    private readonly _http: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  public getWorkCalendar(
    calendarInput: CalendarInput
  ): Observable<ResponseCall> {
    const bodyPost = {
      pParam:
        '{"pUserId": "' +
        calendarInput.pUserId +
        '","pInicio": "' +
        calendarInput.pInicio +
        '","pFin": "' +
        calendarInput.pFin +
        '","pTipoCalendario": "' +
        calendarInput.pTipoCalendario +
        '"}',
    };
    const option = {
      Authorization:
        this._authStorageService.getDataSaved().b64KeyAuth.Authorization,
    };
    return this._http.post<ResponseCall>(this._getWorkCalendar, bodyPost, {
      headers: option,
    });
  }
  public getHolidaysCalendar(
    calendarInput: CalendarInput
  ): Observable<ResponseCall> {
    const bodyPost = {
      pParam:
        '{"pUserId": "' +
        calendarInput.pUserId +
        '","pInicio": "' +
        calendarInput.pInicio +
        '","pFin": "' +
        calendarInput.pFin +
        '"}',
    };
    const option = {
      Authorization:
        this._authStorageService.getDataSaved().b64KeyAuth.Authorization,
    };
    return this._http.post<ResponseCall>(this._getHolidaysCalendar, bodyPost, {
      headers: option,
    });
  }
  public getVacationsCalendar(
    calendarInput: CalendarInput
  ): Observable<ResponseCall> {
    const bodyPost = {
      pParam:
        '{"pUserId": "' +
        calendarInput.pUserId +
        '","pInicio": "' +
        calendarInput.pInicio +
        '","pFin": "' +
        calendarInput.pFin +
        '"}',
    };
    const option = {
      Authorization:
        this._authStorageService.getDataSaved().b64KeyAuth.Authorization,
    };
    return this._http.post<ResponseCall>(this._getVacationsCalendar, bodyPost, {
      headers: option,
    });
  }
}
