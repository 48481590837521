import {HttpClientModule} from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgxLoadingModule} from 'ngx-loading';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {EmployeePortalModule} from './employee-portal/employee-portal.module';
import {CustomDateAdapter} from './shared/services/custom-date-adapter';
import {SharedModule} from './shared/shared.module';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // Angular
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    // 3rd party
    NgxLoadingModule.forRoot({}),

    // Author
    AppRoutingModule,
    AuthModule,
    EmployeePortalModule,
    SharedModule,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: LOCALE_ID, useValue: 'es'},
  ],
  bootstrap: [AppComponent],
  schemas: [],
})
export class AppModule {
}
