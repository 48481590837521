import {Component, HostBinding, Input} from '@angular/core';

/**
 * All valid values for the 'type' property of ButtonComponent
 */
export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Delete = 'delete',
  Disable = 'disable',
}

/**
 * DEMO:
 * It can be used with the anchor and the button component. The peformance is a little better
 * using the anchor tag.
 * <a ekibaButton ekibaButtonType="primary" (click)="yourMethod()">Click me!</a>
 * <button ekibaButton ekibaButtonType="primary" (click)="yourMethod()">Click me!</button>
 */
@Component({
  selector: '[button[ekibaButton]], [a[ekibaButton]]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @HostBinding('class.primary')
  primaryActive = false;
  @HostBinding('class.secondary')
  secondaryActive = false;
  @HostBinding('class.delete')
  deleteActive = false;
  @HostBinding('class.disable')
  disableActive = false;

  private _type: ButtonType|null;
  /**
   * The button type.
   */
  @Input('ekibaButtonType')
  public get type(): ButtonType|null {
    return this._type;
  }

  public set type(newType: ButtonType|null) {
    if (newType === this._type) {
      return;
    }

    switch (newType) {
      case ButtonType.Primary:
        this.primaryActive = true;
        break;
      case ButtonType.Secondary:
        this.secondaryActive = true;
        break;
      case ButtonType.Delete:
        this.deleteActive = true;
        break;
      case ButtonType.Disable:
        this.disableActive = true;
        break;
      default:
        break;
    }
    this._type = newType;  // Read comment below
  }

  /**
   * This snippet can be usefull for future implementations
   * template: [ngClass]="buttonStyle"
   */

  // public get buttonStyle(): any {
  //   const buttonClass = {};
  //   switch (this._type) {
  //     case ButtonType.Primary:
  //       buttonClass[ButtonType.Primary] = true;
  //       break;
  //     case ButtonType.Primary:
  //       buttonClass[ButtonType.Secondary] = true;
  //       break;
  //     case ButtonType.Delete:
  //       buttonClass[ButtonType.Delete] = true;
  //       break;
  //     default:
  //       break;
  //   }
  //   return buttonClass;
  // }
}
