import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

export enum ColumnTypes {
  /**
   * To adjust some masks in the output
   * 'date' is piped with date pipe:  {{ value | date: 'dd/MM/yyyy' }}
   * 'standard' won't be piped.
   */
  Date = 'date',
  Standard = 'standard',
}

export interface TableHeaders {
  // Name of the property as on the database
  name: string;
  // Title shown in the table
  displayName: string;
  // Defines some types for piping the output
  type: ColumnTypes;
}

@Component({
  selector: 'ekiba-add-table',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss'],
})
export class AddTableComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Each of the rows, i.e: ["Fire", 1, false];
   * ATENTION: We type 'any' as this table doesn't know the shape of the items
   * that will be recieved.
   *
   * Set is used to synchronize the input received with _updatedSource. Needed for the filters
   */
  @Input()
  public set dataSource(newValue) {
    if (newValue === this._updatedSource || newValue.length === 0) {
      return;
    }

    newValue = newValue.map((item) => {
      return {
        ...item,
        available: !this._selectedRows.some(selected => selected.id === item.id),
      };
    });

    this._dataSource = new MatTableDataSource(newValue);
    this._updatedSource = newValue;
    this._dataSource.sort = this.sort;
    this._dataSource.paginator = this.paginator;
    if (this.showFilters && this.tableFormControls) {
      this.tableFormControls.forEach(control => control.reset());
    }
  }
  public get dataSource(): any {
    return this._dataSource;
  }

  /**
   * Each of the attributes that a row can have, i.e: ["Type", "height", ....].
   * They must have been set following the TableHeaders Interface
   */
  @Input()
  public columns: TableHeaders[];

  @Input()
  public showFilters = false;

  @Output()
  public selectedRows = new EventEmitter<any>();

  @Output()
  public cancelled = new EventEmitter<any>();

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  public columnLabels: string[] = ['action'];
  public columnTypes = ColumnTypes;
  public headersFilters: TableHeaders[];
  public headersFilterName: string[];

  // Inputs for the filters
  public tableFormControls: FormControl[];

  // Source displayed on the table, it can be modified by the internal filters
  public _dataSource;

  private _destroyed$ = new Subject();
  private _recievedLabels: string[];

  // Static source within the component
  private _updatedSource;

  // Rows selected to be emited outside
  private _selectedRows: any[] = [];

  // Object that'll have the filters
  private _filterValues = {};

  public constructor(
      private paginators: MatPaginatorIntl,
  ) {
    this.paginators.itemsPerPageLabel = 'Trabajadores por página:';
    this.paginators.nextPageLabel = 'Página siguiente';
    this.paginators.previousPageLabel = 'Página anterior';
    this.paginators.firstPageLabel = 'Primera página';
    this.paginators.lastPageLabel = 'Última página';
    this.paginators.getRangeLabel = this.getRangeLabel;
  }

  public ngOnInit(): void {
    this._recievedLabels = this.columns.map((column: TableHeaders) => column.name);
    this.headersFilters = this.columns.map((column: TableHeaders) => column);
    this.headersFilters.push({displayName: 'workAround', name: 's', type: ColumnTypes.Standard});
    this.headersFilterName = this.columns.map((column: TableHeaders) => column.displayName).concat('workAround');
    this.columnLabels = this._recievedLabels.concat(this.columnLabels);
    if (this.showFilters) {
      this._setFilters();
    }
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public ngAfterViewInit(): void {
    this._dataSource.paginator = this.paginator;
  }

  public addRow(addedRow: any): void {
    addedRow = {...addedRow, available: false};
    const indexOfUpdated = this._updatedSource.findIndex((row) => row.id === addedRow.id);
    this._updatedSource[indexOfUpdated] = addedRow;
    this._dataSource = this._updatedSource;
    this._dataSource = new MatTableDataSource(this._dataSource);
    this._dataSource.sort = this.sort;
    this._dataSource.paginator = this.paginator;
    this._selectedRows.push(addedRow);
  }

  public removeRow(removedRow: any): void {
    removedRow = {...removedRow, available: true};
    const indexOfUpdated = this._updatedSource.findIndex((row) => row.id === removedRow.id);
    this._updatedSource[indexOfUpdated] = removedRow;
    this._dataSource = this._updatedSource;
    this._dataSource = new MatTableDataSource(this._dataSource);
    this._dataSource.sort = this.sort;
    this._dataSource.paginator = this.paginator;
    this._selectedRows = this._selectedRows.filter((row) => row.id !== removedRow.id);
  }

  public confirm(): void {
    this.selectedRows.emit(this._selectedRows);
  }

  public cancel(): void {
    this.cancelled.emit(true);
  }

  private _setFilters(): void {
    this._filterValues = this._recievedLabels.reduce((obj, key) => Object.assign(obj, {[key]: ''}), {});
    this._initFormControls();
  }

  private _initFormControls(): void {
    this.tableFormControls = this._recievedLabels.map(() => new FormControl(''));
    this.tableFormControls.forEach(
        (control: FormControl, index) =>
            control.valueChanges.pipe(takeUntil(this._destroyed$), filter(changedValue => changedValue !== null))
                .subscribe((changedValue: string) => {
                  this._filterValues[this._recievedLabels[index]] = changedValue;
                  this._dataSource = this._updatedSource.filter((element) => {
                    for (const key of this._recievedLabels) {
                      if (element[key] &&
                          element[key].toString().toLowerCase().indexOf(this._filterValues[key].toString().toLowerCase()) === -1) {
                        return false;
                      }
                    }
                    return true;
                  });
                  this._dataSource = new MatTableDataSource(this._dataSource);
                  this._dataSource.sort = this.sort;
                  this._dataSource.paginator = this.paginator;
                }));
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }
}
