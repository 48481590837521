<mat-card class="mat-elevation-z8 main-card">
  <div class="form-edit">
      <h1>
        <strong>{{title}}</strong>
      </h1>
    <ng-content></ng-content>
    <div class="button-section">
      <div class="button-section__item">
        <ng-content select="[name=buttonsSection]">
        </ng-content>
      </div>
    </div>
  </div>
</mat-card>