import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ekiba-card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.scss'],
})
export class CardButtonComponent implements OnInit {
  /**
   * Title displayed on the card
   */
  @Input()
  public title: string;

  /**
   * Picture displayed on the card. It should be .jpg
   */
  @Input()
  public picture: string;

  /**
   * Icon based on Material Icons
   */
  @Input()
  public icon: string;

  /**
   * Optinal description
   */
  @Input()
  public description: string;

  public show = false;

  public constructor() {}

  public ngOnInit(): void {
    if (this.title === null) {
      console.error('[title] input from ekiba-card-button is missing.');
    } else if (this.icon == null) {
      console.error('[icon] input from ekiba-card-button is missing.');
    } else {
      this.show = true;
    }
  }
}
