import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AES, enc } from 'crypto-js';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config.service';

import { RoutePaths } from '../constants';
import { AuthStorageService } from '../services/auth.storage.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {
  constructor(
    private readonly _authStorageService: AuthStorageService,
    private readonly _router: Router,
    private readonly _configService: ConfigService
  ) {}

  public canActivate(): boolean | Observable<boolean> {
    if (
      !this._authStorageService.getKeyData() &&
      !this._authStorageService.getUserData()
    ) {
      this._router.navigate([RoutePaths.Login]);
      return false;
    }
    if (localStorage.getItem('TOKEN_PORTAL_DEL_EMPLEADO')) {
      const key = AES.decrypt(
        localStorage.getItem('TOKEN_PORTAL_DEL_EMPLEADO'),
        this._configService.get('user')
      )
        .toString(enc.Utf8)
        .split(';')[2];
      const dateKey = new Date(key);
      const today = new Date();
      dateKey.setMinutes(dateKey.getMinutes() + 360);
      if (today > dateKey) {
        this._authStorageService.removeDataLogin();
        this._router.navigate([RoutePaths.Login]);
        return false;
      }

      const expiryDate = new Date(
        localStorage.getItem('FECHA_CADUCIDAD_PORTAL_DEL_EMPLEADO')
      );
      if (today > expiryDate) {
        this._authStorageService.removeDataLogin();
        this._router.navigate([RoutePaths.Login]);
        return false;
      }
    }
    return true;
  }
}
