import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import {
  Absences,
  EmployeePortalStorageService,
} from 'src/app/employee-portal/employee-portal.storage.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import {
  CalendarInput,
  ListAbsencesCall,
  ResponseCall,
} from './list-absences.call';
import { TABLE_HEADERS_ABSENCES } from './list-absences.constants';

@Component({
  templateUrl: './list-absences.component.html',
  styleUrls: ['./list-absences.component.scss'],
})
export class ListAbsencesComponent implements OnInit {
  constructor(
    private readonly _employeePortalStorageService: EmployeePortalStorageService,
    private readonly _listAbsencesCall: ListAbsencesCall,
    private readonly _authStorageService: AuthStorageService,
    private readonly _errorHandler: ErrorHandlerService
  ) {}

  private _arrayAbsences: Absences[];
  public columns = TABLE_HEADERS_ABSENCES;

  public today: Date = new Date();
  public startDate: Date;
  public spinner = false;
  public year = new FormControl();
  public absences = [];
  private _dateRequest: string;
  private _needUpdate = false;

  public get dateToShow(): string {
    if (!this._dateRequest) {
      return '';
    }
    return this._dateRequest;
  }

  public ngOnInit(): void {
    this._dateRequest = `Año ${this.today.getFullYear()}`;
    this.makeTableValues();
  }

  public updateAbsences(yearSelected: number): void {
    if (this._needUpdate) {
      this.spinner = true;
      const dateStart: Date = new Date(yearSelected, 0, 2);
      const dateEnd: Date = new Date(yearSelected, 11, 32);
      const calendarInput: CalendarInput = {
        pInicio: dateStart.toISOString(),
        pFin: dateEnd.toISOString(),
        pUserId: this._authStorageService.getDataSaved().pUserId,
      };
      this._listAbsencesCall
        .getAbsences(calendarInput)
        .pipe(
          tap(
            (arrayDates: ResponseCall) =>
              (this._employeePortalStorageService.absences = arrayDates.value)
          ),
          catchError((err) =>
            throwError(this._errorHandler.error(err.error?.error?.message))
          ),
          finalize(() => (this.spinner = false))
        )
        .subscribe(() => {
          this.makeTableValues();
        });
    }
    this._needUpdate = false;
  }
  private makeTableValues(): void {
    this.absences = [];
    this._arrayAbsences = JSON.parse(
      this._employeePortalStorageService.absences
    );
    for (const absence of Object.values(this._arrayAbsences)) {
      const abs = {
        'Ending Date': this.formatStringDate(absence['Ending Date'].value),
        'Num Dias Nat Hab': absence['Num Dias Nat Hab'].value,
        'Starting Date': this.formatStringDate(absence['Starting Date'].value),
        Description: absence.Description.value,
        Recurso: absence.Recurso.value,
        Tipo: absence.Tipo.value,
      };
      this.absences.push(abs);
    }
  }

  public chosenYearHandler(
    normalizedMonth: Date,
    datepicker: MatDatepicker<Date>
  ): void {
    this._dateRequest = `Año ${normalizedMonth.getFullYear()}`;
    datepicker.close();
    this._needUpdate = true;
    this.updateAbsences(normalizedMonth.getFullYear());
  }

  public formatStringDate(dateString: string): string {
    const stringDateStart = dateString.split('/');
    const dateStart =
      '20' +
      stringDateStart[2] +
      '-' +
      stringDateStart[1] +
      '-' +
      stringDateStart[0];
    return dateStart;
  }
}
