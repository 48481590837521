<!-- Mobile Filters Outside table -->
<div *ngIf="showFilters" class="mobile-filters">
  <ng-container *ngFor="let headerFilter of headersFilters;let i = index">
    <mat-form-field
      *ngIf="headersFilters.length - 1 !== i && (headerFilter.type === columnTypes.Standard || headerFilter.type === columnTypes.Status)"
      class="filter mat-input-search-wrapper mobile-filters-form-field">
      <input class="wordInput" matInput [formControl]="tableFormControls[i]" autocomplete="off" placeholder="{{columns[i].displayName}}">
      <button matSuffix mat-icon-button class="toggle-suffix" aria-label="Borrar"
        (click)="clearFormControl(tableFormControls[i])" matTooltip="Borrar el filtro">
        <mat-icon class="transparent">clear</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field *ngIf="headersFilters.length - 1 !== i  && headerFilter.type === columnTypes.Date"
      class="filter mat-input-search-wrapper mobile-filters-form-field">
      <mat-label>{{columns[i].displayName}}</mat-label>
      <mat-date-range-input [rangePicker]="picker" [formGroup]="dateFormGroup[i]" style="display: none;">
        <input readonly matStartDate formControlName="startDate" style="display: none;">
        <input readonly matEndDate formControlName="endDate" style="display: none;">
      </mat-date-range-input>
      <input class="wordInput" readonly matInput [value]="getRange(dateFormGroup[i])">
      <mat-datepicker-toggle class="toggle-suffix" matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker-toggle class="toggle-suffix" matSuffix (click)="clearFormGroup(dateFormGroup[i])">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
      </mat-datepicker-toggle>
      <mat-date-range-picker [dateClass]="dateClass" #picker [startAt]="initialDate"></mat-date-range-picker>
    </mat-form-field>
  </ng-container>
</div>

<!-- Table  -->
<div class="table_container mat-elevation-z8">
  <div *ngIf="title" class="header">
    <span>
      <mat-icon class="header-title__icon">
        table_chart
      </mat-icon>
      {{title}}
    </span>
    <button *ngIf="showAddButton" class="header-title__add-icon" mat-mini-fab color="primary" (click)="createRow()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="table-paginator">
    <div class="table-mat-table">
      <table mat-table class="table" [dataSource]="dataSource" matSort>
        <!-- Dynamic content -->
        <ng-container *ngFor="let label of columns" matColumnDef="{{ label.name }}">
          <mat-header-cell class="column" *matHeaderCellDef mat-sort-header [ngClass]="{'small-cell': cellType === Cells.SmallCell, 'medium-cell': cellType === Cells.MediumCell}">
            {{label.displayName}}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="{'small-cell': cellType === Cells.SmallCell, 'medium-cell': cellType === Cells.MediumCell}">
            <div class="mobile-content">
              <div class="mobile-content__label">
                <span class="mobile-label">{{label.displayName}}</span>
              </div>
              <div class="mobile-content__value">
                <div *ngIf="label.type === columnTypes.Date">
                  {{ element[label.name] | date: 'dd/MM/yyyy' }}
                </div>
                <div *ngIf="label.type === columnTypes.Hour">
                  {{ element[label.name] | date: 'HH:mm' }}
                </div>    
                <div *ngIf="label.type === columnTypes.Boolean">
                  {{ element[label.name] ? 'SI' : 'NO' }}
                </div>
                <mat-chip-list *ngIf="label.type === columnTypes.Status" aria-label="Fish selection">
                  <mat-chip [ngClass]="getColorClass(element[label.name])" selected>
                    {{element[label.name]}} 
                  </mat-chip>
                </mat-chip-list>
                <div *ngIf="label.type === columnTypes.Standard">
                  {{ element[label.name] }}
                </div>    
              </div>
            </div>
            <div class="desktop-content">
              <div *ngIf="label.type === columnTypes.Date">
                {{ element[label.name] | date: 'dd/MM/yyyy' }}
              </div>
              <div *ngIf="label.type === columnTypes.Hour">
                {{ element[label.name] | date: 'HH:mm' }}
              </div>    
              <div *ngIf="label.type === columnTypes.Boolean">
                {{ element[label.name] ? 'SI' : 'NO' }}
              </div>
              <mat-chip-list *ngIf="label.type === columnTypes.Status" aria-label="Fish selection">
                <mat-chip [ngClass]="getColorClass(element[label.name])" selected>
                  {{element[label.name]}}
                </mat-chip>
              </mat-chip-list>
              <div *ngIf="label.type === columnTypes.Standard">
                {{ element[label.name]}}
              </div>    
            </div>
          </mat-cell>
        </ng-container>
    
        <!-- Actions -->
        <ng-container matColumnDef="action">
          <mat-header-cell class="columna_final_header" *matHeaderCellDef [ngClass]="{'small-cell': cellType === Cells.SmallCell, 'medium-cell': cellType === Cells.MediumCell}">Acciones</mat-header-cell>
          <mat-cell *matCellDef="let row" class="columna_final" [ngClass]="{'small-cell': cellType === Cells.SmallCell, 'medium-cell': cellType === Cells.MediumCell}">
            <div class="mobile-content">
              <div class="mobile-content__label">
                <span class="mobile-label">Acciones</span>
              </div>
              <div class="mobile-content__value">
                <span>
                  <ng-container *ngIf="customActions">
                    <ng-container *ngFor="let customAction of customActions">
                      <a mat-icon-button 
                        *ngIf="customAction.redirection"
                        [matTooltip]="customAction.labelName"
                        [routerLink]="customRedirection(customAction.redirection, customAction.index, row)"
                        [relativeTo]="null">
                          <mat-icon>{{customAction.iconName}}</mat-icon>
                      </a>
                      <button mat-icon-button  
                        *ngIf="!customAction.redirection"
                        [matTooltip]="customAction.labelName" 
                        (click)="customActionClick(customAction.idAction, row)">
                          <mat-icon>{{customAction.iconName}}</mat-icon>
                      </button>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="showDisable" [ngSwitch]="row.status">
                    <button *ngSwitchCase="0" mat-icon-button (click)="enableDisableRow(row)" matTooltip="Habilitar">
                      <span class="material-icons" >
                        power
                      </span>
                    </button>
                    <button *ngSwitchDefault mat-icon-button (click)="enableDisableRow(row)" matTooltip="Deshabilitar">
                      <span class="material-icons">
                        power_off
                      </span>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="showDefaultActions">
                    <button *ngIf="showCalendarAction" mat-icon-button (click)="changeDates(row)">
                      <span class="material-icons">
                         date_range
                      </span>
                    </button>
                    <a *ngIf="redirection" matTooltip="Visualizar" mat-icon-button [routerLink]="getLink(row)" [relativeTo]="null">
                      <mat-icon>visibility</mat-icon>
                    </a>
                    <button *ngIf="showEditButton" matTooltip="Editar" mat-icon-button (click)="updateRow(row)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="showDeleteButton" matTooltip="Eliminar" mat-icon-button (click)="deleteRow(row)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </ng-container>
                </span>
              </div>
            </div>
            <span class="desktop-content">
              <ng-container *ngIf="customActions">
                <ng-container *ngFor="let customAction of customActions">
                  <a mat-icon-button 
                    *ngIf="customAction.redirection"
                    [matTooltip]="customAction.labelName"
                    [routerLink]="customRedirection(customAction.redirection, customAction.index, row)"
                    [relativeTo]="null">
                      <mat-icon>{{customAction.iconName}}</mat-icon>
                  </a>
                  <button mat-icon-button  
                    *ngIf="!customAction.redirection"
                    [matTooltip]="customAction.labelName" 
                    (click)="customActionClick(customAction.idAction, row)">
                      <mat-icon>{{customAction.iconName}}</mat-icon>
                  </button>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="showDisable" [ngSwitch]="row.status">
                <button *ngSwitchCase="0" mat-icon-button (click)="enableDisableRow(row)" matTooltip="Habilitar">
                  <span class="material-icons" >
                    power
                  </span>
                </button>
                <button *ngSwitchDefault mat-icon-button (click)="enableDisableRow(row)" matTooltip="Deshabilitar">
                  <span class="material-icons">
                    power_off
                  </span>
                </button>
              </ng-container>
              <ng-container *ngIf="showDefaultActions">
                <button *ngIf="showCalendarAction" mat-icon-button (click)="changeDates(row)">
                  <span class="material-icons">
                     date_range
                  </span>
                </button>
                <a *ngIf="redirection" matTooltip="Visualizar" mat-icon-button [routerLink]="getLink(row)" [relativeTo]="null">
                  <mat-icon>visibility</mat-icon>
                </a>
                <button *ngIf="showEditButton" matTooltip="Editar" mat-icon-button (click)="updateRow(row)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="showDeleteButton" matTooltip="Eliminar" mat-icon-button (click)="deleteRow(row)">
                  <mat-icon>delete</mat-icon>
                </button>
              </ng-container>
            </span>
          </mat-cell>
        </ng-container>
    
        <!-- Filters -->
        <ng-container *ngIf="showFilters">
          <ng-container *ngFor="let headerFilter of headersFilters;let i = index"
            matColumnDef="{{headerFilter.displayName}}">
            <mat-header-cell *matHeaderCellDef [ngClass]="{'small-cell': cellType === Cells.SmallCell, 'medium-cell': cellType === Cells.MediumCell}">
              <mat-form-field *ngIf="headersFilters.length - 1 !== i && (headerFilter.type === columnTypes.Standard || headerFilter.type === columnTypes.Status)"
                class="filter mat-input-search-wrapper" floatLabel="never">
                <input class="wordInput" matInput [formControl]="tableFormControls[i]" autocomplete="off">
                <button matSuffix mat-icon-button class="toggle-suffix" aria-label="Borrar" (click)="clearFormControl(tableFormControls[i])" matTooltip="Borrar el filtro">
                  <mat-icon class="transparent">clear</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field  *ngIf="headersFilters.length - 1 !== i  && headerFilter.type === columnTypes.Date"
              class="filter mat-input-search-wrapper" floatLabel="never">
              <mat-date-range-input [rangePicker]="picker" [formGroup]="dateFormGroup[i]" style="display: none;">
                <input readonly matStartDate formControlName="startDate" style="display: none;">
                <input readonly matEndDate formControlName="endDate"style="display: none;">
              </mat-date-range-input> 
              <input  class="wordInput" readonly matInput [value]="getRange(dateFormGroup[i])">
              <mat-datepicker-toggle class="toggle-suffix" matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker-toggle class="toggle-suffix" matSuffix (click)="clearFormGroup(dateFormGroup[i])">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-date-range-picker  [dateClass]="dateClass" #picker [startAt]="initialDate"></mat-date-range-picker>
            </mat-form-field>
            </mat-header-cell>
          </ng-container>
        </ng-container>
    
        <!-- Content -->
        <mat-header-row *matHeaderRowDef="columnLabels" class="table_header"></mat-header-row>
        <ng-container *ngIf="showFilters">
          <mat-header-row class="no-default-height" *matHeaderRowDef="headersFilterName"></mat-header-row>
        </ng-container>
        <mat-row *matRowDef="let row; columns: columnLabels;"></mat-row>
      </table>
    </div>   
    <mat-paginator *ngIf="showPaginator" [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" [showFirstLastButtons]="showFirstLastButtonsOfPaginator">
    </mat-paginator>
  </div>
</div>
