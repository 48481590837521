<h1 mat-dialog-title>
  {{data.headerMessage}}
</h1>
<div mat-dialog-content>
  <h3>{{data.errorMessage}}</h3>
  <p *ngFor="let item of data.conflictLines">
    <ng-container *ngIf="isAnObject(item)">
      <ng-container *ngFor="let subItem of item | keyvalue; let i = index">
        {{subItem.key | titlecase}}: {{subItem.value}}
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isAString(item)">
      {{item}}
    </ng-container>
  </p>
</div>
<div class="buttons-section" mat-dialog-actions>
  <button type="submit" class="cancel-button" ekibaButton ekibaButtonType="secondary" (click)="closeDialog()">
    Aceptar
  </button>
  <button *ngIf="data.continue" type="submit" class="confirm-button" ekibaButton ekibaButtonType="delete" (click)="confirm()">
    Confirmar
  </button>
</div>
