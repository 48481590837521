<div class="button-container">
  <mat-form-field *ngIf="showFilter" class="searcher">
    <mat-icon class="searchIcon" matPrefix>search</mat-icon>
    <input class="wordInput" [(ngModel)]="wordInput" matInput  autofocus autocomplete="off">
  </mat-form-field>
  <mat-form-field *ngIf="showFilterDate"  class="dateFilter">
    <mat-date-range-input [rangePicker]="picker" style="display: none;">
      <input matInput readonly
      matStartDate 
      style="display: none;"
      [(ngModel)]="dateFilterStart" 
      autocomplete="off">
      <input matInput readonly
      matEndDate 
      style="display: none;"
      [(ngModel)]="dateFilterEnd" 
      autocomplete="off">
    </mat-date-range-input>
    <input matInput readonly 
      class="dateInput"
      [(ngModel)]="monthYear" 
      autocomplete="off">      
    <mat-datepicker-toggle class="toggle-suffix" matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker-toggle class="toggle-suffix" matSuffix (click)="clearDate()" matTooltip="Esta operación traerá todos los registros">
      <mat-icon matDatepickerToggleIcon>clear</mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker 
      #picker startView="year" 
      panelClass="example-month-picker">
    </mat-date-range-picker>
  </mat-form-field>
  
  <div class="filler"></div>
  <div class="action-buttons">
    <a *ngIf="addItemName" class="button-add" ekibaButton ekibaButtonType="primary" (click)="addItem()">
      <mat-icon class="center-icon__add">add_circle_outline</mat-icon>
      {{addItemName}}
    </a>
    <a *ngIf="goBackName" class="button-back" ekibaButton ekibaButtonType="secondary" (click)="goBack()">
      <mat-icon class="center-icon__return">arrow_back_ios</mat-icon>
      {{goBackName}}
    </a>
  </div>
</div>