import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {DateManagerService} from 'src/app/shared/services/date-manager.service';

import {Action} from '../../../interfaces/popup';

import {DeletePopupInput, DeletePopupOutput, DeleteTablePopupComponent,} from './pop-up/delete-table.popup.component';

export enum ColumnTypes {
  /**
   * To adjust some masks in the output
   * 'date' is piped with date pipe:  {{ value | date: 'dd/MM/yyyy' }}
   * 'standard' won't be piped.
   */
  Date = 'date',
  Hour = 'hour',
  Standard = 'standard',
  Boolean = 'boolean',
  Status = 'status',
}

export interface TableHeaders {
  // Name of the property as on the database
  name: string;
  // Title shown in the table
  displayName: string;
  // Defines some types for piping the output
  type: ColumnTypes;
}

export interface CustomAction {
  // Svg mat-icon
  iconName: string;
  // Tooltip name
  labelName: string;
  // Needed to identify the action. It might be redundant cause we have the
  // array index already but it's easier to track
  idAction: number;
  // RoutePath for [routerLink] if needed.
  redirection?: string;
  // Row property by which the redirect is indexed
  index?: string;
}

export interface StatusColorValue {
  // mat-chip color bassed on a css class.
  class: string;
  // Linked value
  linkedValue: string;
}

export enum CellType {
  // Needed for those times when the AMOUNT OF CELLS is too large and we want to
  // avoid scrolling.
  SmallCell = 'small-cell',
  // Neeed when the amount of ACTIONS (customActions) breaks the line too soon.
  MediumCell = 'medium-cell',
}

export interface CustomActionClicked {
  idAction: number;
  row: any;
}

@Component({
  selector: 'ekiba-master-table(no-used)',
  templateUrl: './master-table.component.html',
  styleUrls: ['./master-table.component.scss'],
})
export class MasterTableComponent implements OnInit, OnDestroy {
  @Input()  // Optional
  public title: string;

  /**
   * Each of the rows, i.e: ["Fire", 1, false];
   * ATENTION: We type 'any' as this table doesn't know the shape of the items
   * that will be recieved.
   *
   * Set is used to synchronize the input received with _updatedSource. Needed
   * for the filters
   */
  @Input()
  public set dataSource(newValue) {
    if (newValue === this._updatedSource) {
      return;
    }
    this._dataSource = new MatTableDataSource(newValue);
    this._updatedSource = newValue;
    this._dataSource.sort = this.sort;

    if (this.showPaginator) {
      this._dataSource.paginator = this.paginator;
    }

    this._resetFormControls();
  }
  public get dataSource(): any {
    return this._dataSource;
  }

  /**
   * Each of the attributes that a row can have, i.e: ["Type", "height", ....].
   * They must have been set following the TableHeaders Interface
   */
  @Input() public columns: TableHeaders[];

  /**
   * Default actions are (Edit and Delete) but sometime they're not enough
   * customActions come to help! Send extra actions here and receive the output
   * at @output customActionClicked($event)
   */
  @Input() public customActions: CustomAction[];

  /**
   * Linked color values based on type.Status from Table Headers
   * const STATUS_COLOR_VALUES: StatusColorValue[] = [
   * {
   *   class: 'main-theme',
   *   linkedValue: 'Aprobado',
   * },
   * {
   *   class: 'secondary-theme',
   *   linkedValue: 'Rechazado',
   * },
   * ]
   */
  @Input() public statusColorValues: StatusColorValue[];

  // TODO: update all [showFilters]="true"
  @Input() public showFilters = false;

  @Input() public showDefaultActions = true;

  @Input() public showAddButton = false;

  @Input() public showEditButton = true;

  @Input() public showDeleteButton = true;

  @Input() public redirection: string;

  @Input() public showPaginator = true;

  @Input() public showCalendarAction = false;

  @Input() public showDisable = false;

  @Input() public cellType: CellType;

  @Input()
  public set initialDate(newValue: Date) {
    if (!!newValue && newValue === this._initialDate) {
      return;
    }
    this._initialDate = newValue ? new Date(newValue) : new Date();
  }
  public get initialDate(): Date {
    return this._initialDate;
  }

  @Output() public deletedRow = new EventEmitter<any>();

  @Output() public enableDisabledRow = new EventEmitter<any>();

  @Output() public updatedRow = new EventEmitter<any>();

  @Output() public createdRow = new EventEmitter<any>();

  @Output()
  public customActionClicked = new EventEmitter<CustomActionClicked>();

  @Output() public changedDates = new EventEmitter<any>();

  @ViewChild(MatSort, {static: true}) protected sort: MatSort;

  @ViewChild(MatPaginator) protected paginator: MatPaginator;

  public columnLabels: string[] = ['action'];
  public columnTypes = ColumnTypes;
  public headersFilters: TableHeaders[];
  public headersFilterName: string[];

  // Inputs for the plain string filters
  public tableFormControls: FormControl[];

  // Inputs for the Date object;
  // each form group will have this two properties
  // {startDate, endDate}
  public dateFormGroup: FormGroup[];

  // Source displayed on the table, it can be modified by the internal filters
  public _dataSource;

  public Cells = CellType;
  public showFirstLastButtonsOfPaginator = true;

  private _initialDate;

  private _destroyed$ = new Subject();
  protected _recievedLabels: string[];

  // Static source within the component
  private _updatedSource;

  // Object that'll have the filters
  protected _filterValues = {};

  public constructor(
      protected readonly paginators: MatPaginatorIntl,
      protected readonly _dms: DateManagerService,
      protected readonly _dialog: MatDialog,
  ) {
    this.paginators.itemsPerPageLabel = 'Items por página:';
    this.paginators.nextPageLabel = 'Página siguiente';
    this.paginators.previousPageLabel = 'Página anterior';
    this.paginators.firstPageLabel = 'Primera página';
    this.paginators.lastPageLabel = 'Última página';
    this.paginators.getRangeLabel = this.getRangeLabel;
  }

  public ngOnInit(): void {
    this._recievedLabels =
        this.columns.map((column: TableHeaders) => column.name);
    this.headersFilters = this.columns.map((column: TableHeaders) => column);
    if (this.showDefaultActions) {
      this.headersFilters.push(
          {displayName: 'workAround', name: 's', type: ColumnTypes.Standard});
      this.headersFilterName =
          this.columns.map((column: TableHeaders) => column.displayName)
              .concat('workAround');
      this.columnLabels = this._recievedLabels.concat(this.columnLabels);
    } else {
      this.headersFilterName =
          this.columns.map((column: TableHeaders) => column.displayName);
      this.columnLabels = this._recievedLabels;
    }

    if (this.showFilters) {
      this._setFilters();
    }
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public createRow(): void {
    this.createdRow.emit();
  }

  public deleteRow(row: any): void {
    const deleteRowInput: DeletePopupInput = {
      action: Action.delete,
      headerMessage: '¿Está seguro de que desea eliminar este registro?',
      item: row,
      keys: this._recievedLabels,
      labels: this.columns.map((column: TableHeaders) => column.displayName),
    };

    const dialogRef = this._dialog.open(DeleteTablePopupComponent, {
      width: '500px',
      data: deleteRowInput,
    });

    dialogRef.afterClosed()
        .pipe(filter(
            (result: DeletePopupOutput) => result.event === Action.delete))
        .subscribe(() => {
          this.deletedRow.emit(row);
        });
  }

  public enableDisableRow(row: any): void {
    this.enableDisabledRow.emit(row);
  }

  public updateRow(row: any): void {
    this.updatedRow.emit(row);
  }

  public getLink(row: any): string {
    return `${this.redirection}/${row.id}`;
  }

  public customActionClick(idAction: number, row: any): void {
    const customAction: CustomActionClicked = {
      idAction,
      row,
    };
    this.customActionClicked.emit(customAction);
  }

  public customRedirection(redirection: string, index: string, row: any):
      string {
    return `${redirection}/${row[index]}`;
  }

  public changeDates(row: any): void {
    this.changedDates.emit(row);
  }

  public clearFormControl(tableFormControl: FormControl): void {
    tableFormControl.setValue(null);
    tableFormControl.setValue('');
  }

  public clearFormGroup(formGroup: FormGroup): void {
    formGroup.controls.startDate.setValue(null);
    formGroup.controls.startDate.setValue(undefined);
    formGroup.controls.endDate.setValue(null);
    formGroup.controls.endDate.setValue(undefined);
  }

  public getRange(dateFormGroup: FormGroup): string {
    const {startDate, endDate} = dateFormGroup.value;
    if (!startDate || !endDate) {
      return '';
    }

    if (new Date(startDate).getTime() === new Date(endDate).getTime()) {
      return `${this._dms.getEuropeanFormat(startDate)}`;
    }

    return `${this._dms.getEuropeanFormat2(startDate)} - ${
        this._dms.getEuropeanFormat2(endDate)}`;
  }

  public getColorClass(value: string|undefined): object {
    const cssClasses = {};
    if (!value || !this.statusColorValues) {
      return cssClasses;
    }
    const _class = this.statusColorValues
                       .find(
                           (statusColor: StatusColorValue) =>
                               statusColor.linkedValue === value)
                       ?.class;
    if (_class) {
      cssClasses[_class] = true;
    }
    return cssClasses;
  }

  private _setFilters(): void {
    this._filterValues = this._recievedLabels.reduce(
        (obj, key) => Object.assign(obj, {[key]: ''}), {});
    this._initFormControls();
  }

  protected _initFormControls(): void {
    this.tableFormControls =
        this._recievedLabels.map(() => new FormControl(''));
    this.dateFormGroup = this._recievedLabels.map(
        () => new FormGroup(
            {
              startDate: new FormControl(''),
              endDate: new FormControl(''),
            },
            ));

    this.tableFormControls.forEach((control: FormControl, index) => {
      control.valueChanges
          .pipe(
              takeUntil(this._destroyed$),
              filter(
                  (changedValue: string|Date) =>
                      this._filterChangesStandardFormControl(
                          changedValue, index)),
              tap((changedValue: string|Date) => {
                this._filterValues[this._recievedLabels[index]] = changedValue;
                this._applyFilters(index);
                this._dataSource = new MatTableDataSource(this._dataSource);
                this._dataSource.sort = this.sort;
                this._dataSource.paginator = this.paginator;
              }))
          .subscribe();
    });

    this.dateFormGroup.forEach((formGroup: FormGroup, index: number) => {
      formGroup.controls.endDate.valueChanges
          .pipe(
              takeUntil(this._destroyed$),
              filter(
                  (changedValue: Date|undefined) =>
                      this._filterChangesDateFormControl(changedValue, index)),
              tap((changedValue: Date|undefined) => {
                const {startDate} = this.dateFormGroup[index].value;
                this._filterValues[this._recievedLabels[index]] = {
                  startDate: startDate ? startDate.getTime() : '',
                  endDate: startDate ? changedValue.getTime() : '',
                };
                this._applyFilters(index);
                this._dataSource = new MatTableDataSource(this._dataSource);
                this._dataSource.sort = this.sort;
                this._dataSource.paginator = this.paginator;
              }))
          .subscribe();
    });
  }

  protected _filterChangesStandardFormControl(changedValue: string|Date, index):
      boolean {
    return changedValue !== null && !(changedValue instanceof Date) &&
        this._filterValues[this._recievedLabels[index]] !== changedValue;
  }

  protected _filterChangesDateFormControl(changedValue: Date|undefined, index):
      boolean {
    return changedValue !== null &&
        this._filterValues[this._recievedLabels[index]]?.endDate !==
        changedValue?.getTime();
  }

  protected _resetFormControls(): void {
    if (this.showFilters && this.tableFormControls) {
      this.tableFormControls.forEach(control => control.reset());
    }
  }

  private _applyFilters(index: number): void {
    this._dataSource = this._updatedSource.filter((element) => {
      for (const key of this._recievedLabels) {
        if ((!element[key] && key === this._recievedLabels[index]) ||
            (element[key] && typeof this._filterValues[key] === 'string' &&
             (element[key].toString().toLowerCase().indexOf(
                  this._filterValues[key].toString().toLowerCase()) === -1))) {
          return false;
        }
        if (element[key] && typeof this._filterValues[key] === 'object' &&
            !!this._filterValues[key].startDate &&
            !!this._filterValues[key].endDate &&
            (this._filterValues[key].startDate >
                 new Date(element[key]).getTime() ||
             new Date(element[key]).getTime() >
                 this._filterValues[key].endDate)) {
          return false;
        }
      }
      return true;
    });
  }

  private getRangeLabel(page: number, pageSize: number, length: number):
      string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }
}
