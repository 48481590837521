import {ColumnTypes, TableHeaders} from 'ekiba-master-table';

export const TABLE_HEADERS_RECOGNITIONS: TableHeaders[] = [
  {
    name: 'Producto',
    displayName: 'Producto.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Producto Descrripcion',
    displayName: 'Descrripción',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Fecha Reconocimiento',
    displayName: 'Fecha Reconocimiento.',
    type: ColumnTypes.Date,
  },
  {
    name: 'Fecha Vto',
    displayName: 'Fecha Vto.',
    type: ColumnTypes.Date,
  },
  {
    name: 'Frecuencia',
    displayName: 'Frecuencia.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Resultado',
    displayName: 'Resultado.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Fecha Apto Condicionado',
    displayName: 'Fecha Apto Condicionado.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Fecha Renuncia',
    displayName: 'Fecha Renuncia.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Centro Medico',
    displayName: 'Centro Medico.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Centro Medico Nombre',
    displayName: 'Centro Medico Nombre.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Fecha Recepcion Docs',
    displayName: 'Fecha recepción',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Enlace Web Resultados',
    displayName: 'Enlace Web Resultados.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Característica',
    displayName: 'Característica.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Comentarios',
    displayName: 'Comentarios.',
    type: ColumnTypes.Standard,
  },
];
