<ng-container class="container-hours-booked" [ngSwitch]="spinner">
  <div class="hours-graph-container">
    <mat-card-title>Fichaje - Listado Fichajes</mat-card-title>
    <hr class="hr-title" />
    <mat-card-subtitle>Listado con el histórico de fichajes </mat-card-subtitle>
    <br />
    <mat-form-field class="mat-form-dateYear" appearance="outline">
      <mat-icon matPreffix>today</mat-icon>
      <input
        class="input-year"
        matInput
        readonly
        [matDatepicker]="picker"
        [formControl]="year"
      />
      <span class="year-span">{{ dateToShow }}</span>
      <mat-datepicker-toggle
        class="datepiker-toggle-icon"
        matSuffix
        [for]="picker"
      >
        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker
        #picker
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event, picker)"
      >
      </mat-datepicker>
    </mat-form-field>
    <mat-spinner class="spinner" *ngSwitchCase="true"></mat-spinner>
    <ekiba-master-table
      *ngSwitchDefault
      [showDefaultActions]="false"
      [dataSource]="listFichajes"
      [columns]="columns"
      [showFilters]="true"
      [statusColorValues]="statusColorsValues"
      [cellType]="CELL_TYPE"
    >
    </ekiba-master-table>
  </div>
</ng-container>
