import {ColumnTypes, TableHeaders} from 'ekiba-master-table';

export const TABLE_HEADERS_COURSES: TableHeaders[] = [
  {
    name: 'Fecha Inicio',
    displayName: 'Fecha inicio',
    type: ColumnTypes.Date,
  },
  {
    name: 'Fecha Fin',
    displayName: 'Fecha fin',
    type: ColumnTypes.Date,
  },
  {
    name: 'Situacion',
    displayName: 'Situación',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Codigo',
    displayName: 'Curso',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Descripcion',
    displayName: 'Descripción curso',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Evaluacion Final',
    displayName: 'Evaluación final',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Fecha Evaluacion',
    displayName: 'Fecha evaluación',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Duracion Horas',
    displayName: 'Duración horas',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Formador',
    displayName: 'Formador.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Nombre Centro Formacion',
    displayName: 'Centro formación',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Lugar',
    displayName: 'Lugar.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Horas Presenciales',
    displayName: 'Horas presenciales',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Horas Distancia',
    displayName: 'Horas distancia',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Persona Evaluacion Formacion',
    displayName: 'Persona evaluación formación',
    type: ColumnTypes.Standard,
  },
];
