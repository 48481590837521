import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import {
  CELL_TYPE,
  Course,
  EmployeePortalStorageService,
} from 'src/app/employee-portal/employee-portal.storage.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { CalendarInput, CoursesCall, ResponseCall } from './courses.call';
import { TABLE_HEADERS_COURSES } from './courses.constants';

@Component({
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  constructor(
    private readonly _employeePortalStorageService: EmployeePortalStorageService,
    private readonly _coursesCall: CoursesCall,
    private readonly _authStorageService: AuthStorageService,
    private readonly _errorHandler: ErrorHandlerService
  ) {}

  private _Courses: Course[];
  public columns = TABLE_HEADERS_COURSES;

  public today: Date = new Date();
  public startDate: Date;
  public spinner = false;
  public year = new FormControl();
  public courses = [];
  public cellType = CELL_TYPE;
  private _dateRequest: string;

  public get dateToShow(): string {
    if (!this._dateRequest) {
      return '';
    }
    return this._dateRequest;
  }

  public ngOnInit(): void {
    this._dateRequest = `Año ${this.today.getFullYear()}`;
    this.makeTableValues();
  }

  public updateCourses(yearSelected: number): void {
    this.spinner = true;
    const dateStart: Date = new Date(yearSelected, 0, 2);
    const dateEnd: Date = new Date(yearSelected, 11, 32);
    const calendarInput: CalendarInput = {
      pInicio: dateStart.toISOString(),
      pFin: dateEnd.toISOString(),
      pUserId: this._authStorageService.getDataSaved().pUserId,
    };
    this._coursesCall
      .getCourses(calendarInput)
      .pipe(
        tap((arrayCourses: ResponseCall) => {
          this._employeePortalStorageService.courses = arrayCourses.value;
          this.makeTableValues();
        }),
        catchError((err) =>
          throwError(this._errorHandler.error(err.error?.error?.message))
        ),
        finalize(() => (this.spinner = false))
      )
      .subscribe();
  }
  private makeTableValues(): void {
    this.courses = [];
    this._Courses = JSON.parse(this._employeePortalStorageService.courses);
    for (const course of Object.values(this._Courses)) {
      const mtr = {
        Situacion: course.Situacion.value,
        'Fecha Inicio': this.formatStringDate(course['Fecha Inicio'].value),
        'Fecha Fin': this.formatStringDate(course['Fecha Fin'].value),
        Codigo: course.Codigo.value,
        Descripcion: course.Descripcion.value,
        'Evaluacion Final': course['Evaluacion Final'].value,
        'Fecha Evaluacion': course['Fecha Evaluacion'].value,
        'Duracion Horas': course['Duracion Horas'].value,
        Formador: course.Formador.value,
        'Nombre Centro Formacion': course['Nombre Centro Formacion'].value,
        Lugar: course.Lugar.value,
        'Horas Presenciales': course['Horas Presenciales'].value,
        'Horas Distancia': course['Horas Distancia'].value,
        'Persona Evaluacion Formacion':
          course['Persona Evaluacion Formacion'].value,
      };
      this.courses.push(mtr);
    }
  }

  public chosenYearHandler(
    normalizedMonth: Date,
    datepicker: MatDatepicker<Date>
  ): void {
    this._dateRequest = `Año ${normalizedMonth.getFullYear()}`;
    datepicker.close();
    this.updateCourses(normalizedMonth.getFullYear());
  }

  public formatStringDate(dateString: string): string {
    if (dateString) {
      const stringDateStart = dateString.split('/');
      const dateStart =
        '20' +
        stringDateStart[2] +
        '-' +
        stringDateStart[1] +
        '-' +
        stringDateStart[0];
      return dateStart;
    }
    return '';
  }
}
