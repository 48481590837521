import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import { ConfigService } from 'src/app/shared/services/config.service';

export interface CalendarInput {
  pUserId: string;
  pInicio: string;
  pFin: string;
  pDescripcion?: string;
}
export interface ResponseCall {
  '@odata.context': string;
  value: string;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RequestVacationsCall {
  private readonly _addRequestVacations = this._configService.url(
    'urlRequestVacations'
  );
  private readonly _getRequestVacationDays = this._configService.url(
    'urlRequestVacationDays'
  );

  public constructor(
    private readonly _authStorageService: AuthStorageService,
    private readonly _http: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  public addRequestVacations(
    calendarInput: CalendarInput
  ): Observable<ResponseCall> {
    const bodyPost = {
      pParam:
        '{"pUserId": "' +
        calendarInput.pUserId +
        '","pInicio": "' +
        calendarInput.pInicio +
        '","pFin": "' +
        calendarInput.pFin +
        '","pDescripcion": "' +
        calendarInput.pDescripcion +
        '"}',
    };
    const option = {
      Authorization:
        this._authStorageService.getDataSaved().b64KeyAuth.Authorization,
    };
    return this._http.post<ResponseCall>(this._addRequestVacations, bodyPost, {
      headers: option,
    });
  }
  public getWorkDays(calendarInput: CalendarInput): Observable<ResponseCall> {
    const bodyPost = {
      pParam:
        '{"pUserId": "' +
        calendarInput.pUserId +
        '","pInicio": "' +
        calendarInput.pInicio +
        '","pFin": "' +
        calendarInput.pFin +
        '"}',
    };
    const option = {
      Authorization:
        this._authStorageService.getDataSaved().b64KeyAuth.Authorization,
    };
    return this._http.post<ResponseCall>(
      this._getRequestVacationDays,
      bodyPost,
      { headers: option }
    );
  }
}
