import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItems } from 'ekiba-navbar';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { tap } from 'rxjs/internal/operators/tap';

import { HOME_ROUTE } from './app.component.constants';
import { AUTH_ROUTES } from './auth/constants';
import { AuthStorageService } from './auth/services/auth.storage.service';
import { LoginOutput } from './auth/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './shared/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public userData: LoginOutput;
  public homeRoute = HOME_ROUTE;
  public fullNavbar: MenuItems[] = [];
  public datosUsuario;
  private _destroyed$ = new Subject();

  public constructor(
    private readonly _router: Router,
    private readonly _authStorageService: AuthStorageService,
    private readonly _http: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  public async ngOnInit(): Promise<void> {
    const response = await this.configProxy();
    this._configService.proxyEnabled = response.proxy;
    
    registerLocaleData(es);
    this.userData = this._authStorageService.getDataSaved();

    this._authStorageService.permisos$
      .pipe(
        filter((permisos: string[]) => !!permisos?.length),
        tap((permisos: string[]) => {
          this.fullNavbar = this._buildMenuItems(permisos);
        })
      )
      .subscribe();

    if (!this._authStorageService.permisos?.length) {
      this._authStorageService.permisos =
        this._authStorageService.getPermisosData()?.length
          ? JSON.parse(this._authStorageService.getPermisosData())
          : [];
    }

    if (!this._authStorageService.company) {
      this._authStorageService.company = JSON.parse(
        this._authStorageService.getCompanyData()
      );
    }
  }

  public configProxy():any  {
    return new Promise<number>((resolve) => {
      this._http
          .get<any>('bc/configProxy.json').subscribe((resConfig: any) => {
            resolve(resConfig);
          })
    });
};

  // public configProxy(): void {
  //   this._http
  //     .get<any>('assets/bc/configProxy.json')
  //     .pipe(
  //       tap((resConfig: any) => {
  //         console.log(resConfig);
  //         if (!!resConfig.proxy) {
  //           this._configService.proxyEnabled = true;
  //         } else {
  //           this._configService.proxyEnabled = false;
  //         }
  //       })
  //     )
  //     .subscribe();
  // }
  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public get isLoading(): boolean {
    return !this.fullNavbar || !this.userData;
  }

  public logOut(): void {
    this._authStorageService.removeDataLogin();
  }

  public isLoginSection(): boolean {
    const isLogin = AUTH_ROUTES.some((route) => route === this._router.url);
    if (!isLogin) {
      this.userData = this._authStorageService.getDataSaved();
    }
    return isLogin;
  }

  private _buildMenuItems(permisos: string[]) {
    const items: MenuItems[] = [
      {
        routes: ['inicio'],
        icon: 'home',
        label: 'Inicio',
        menuItems: [],
      },
    ];

    for (let permission of permisos) {
      switch (permission) {
        case 'CAL':
          items.push({
            routes: ['calendario-laboral'],
            icon: 'today',
            label: 'Calendario Laboral',
            menuItems: [],
          });
          break;
        case 'PRE':
          items.push({
            routes: [''],
            icon: 'equalizer',
            label: 'Presencia',
            menuItems: [
              {
                routes: ['resumen-horas-fichadas'],
                label: 'Resumen horas fichadas',
              },
              {
                routes: ['listado-ausencias'],
                label: 'Listado Ausencias',
              },
            ],
          });
          break;
        case 'VAC':
          items.push({
            routes: [''],
            icon: 'beach_access',
            label: 'Vacaciones',
            menuItems: [
              {
                routes: ['solicitud-vacaciones'],
                label: 'Añadir Solicitud',
              },
              {
                routes: ['listado-vacaciones'],
                label: 'Listado vacaciones',
              },
            ],
          });
          break;
        case 'MAT':
          items.push({
            routes: ['materiales'],
            icon: 'business_center',
            label: 'Material',
            menuItems: [],
          });
          break;
        case 'REC':
          items.push({
            routes: ['reconocimientos'],
            icon: 'text_snippet',
            label: 'Reconocimientos',
            menuItems: [],
          });
          break;
        case 'CUR':
          items.push({
            routes: ['cursos'],
            icon: 'school',
            label: 'Cursos',
            menuItems: [],
          });
          break;
        case 'CON':
          items.push({
            routes: ['contacto'],
            icon: 'mail_outline',
            label: 'Contacto',
            menuItems: [],
          });
          break;
        case 'FIC':
          items.push({
            routes: [''],
            icon: 'co_present',
            label: 'Fichaje',
            menuItems: [
              {
                routes: ['fichar'],
                label: 'Fichar',
              },
              {
                routes: ['listado-fichaje'],
                label: 'Listado fichaje',
              },
            ],
          });
          break;
      }
    }
    return items;
  }
}
