<ng-container class="container-hours-booked" [ngSwitch]="spinner">
  <div class="hours-graph-container">
    <mat-card-title>Presencia - Resumen de horas fichadas</mat-card-title>
    <hr class="hr-title">
    <mat-card-subtitle>En el siguiente gráfico puedes ver las horas fichadas en comparación con las horas reales.
    </mat-card-subtitle>
    <br>
    <div class="total-div-hours">
      <mat-form-field class="mat-form-dateYear" appearance="outline">
        <mat-icon matPreffix>today</mat-icon>
        <input class="input-year" matInput readonly [matDatepicker]="picker" [formControl]="year">
        <span class="year-span">{{dateToShow}}</span>
        <mat-datepicker-toggle class="datepiker-toggle-icon" matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearHandler($event, picker)">
        </mat-datepicker>
      </mat-form-field>
      <ng-container *ngSwitchDefault>
        <a>H.Reales: {{totalsHours[0] | number}}</a>
        <a>H.Registradas: {{totalsHours[1]| number}} </a>
        <a>H.Actividad: {{totalsHours[2]| number}} </a>
      </ng-container>
    </div>
    <mat-spinner class="spinner" *ngSwitchCase="true"></mat-spinner>
    <mat-card *ngSwitchDefault class="card-graph">
      <div class="title-card-graph">
        <span>Resumen Horas</span>
      </div>
      <div>
        <div>
          <div style="display: block">
            <canvas baseChart [data]="barChartData" [options]="barChartOptions" [type]="barChartType">
            </canvas>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>