import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import { ConfigService } from 'src/app/shared/services/config.service';

export interface MailInput {
  pUserId: string;
  pInicio: string;
  pFin: string;
  pDescripcion?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ContactCall {
  private readonly _sendMail =
    this._configService.get('baseAppUrl') + '/mailer/send.php';

  public constructor(
    private readonly _authStorageService: AuthStorageService,
    private readonly _http: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  public sendMail(textToBody: string): Observable<any> {
    const user = this._authStorageService.getUserData();
    const body = {
      tipo: 'portal-empleado',
      source: 'portal-empleado',
      body: textToBody ? textToBody : '',
      user_id: user ? user : '',
      to: this._configService.get('emailContacto'),
    };
    return this._http.post<any>(this._sendMail, body);
  }
}
