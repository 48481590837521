import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import {
  EMPLOYEE_PORTAL_CONTACT_ROUTE,
  EMPLOYEE_PORTAL_COURSES_ROUTE,
  EMPLOYEE_PORTAL_FICHAR_ROUTE,
  EMPLOYEE_PORTAL_HISTORICO_FICHAJE_ROUTE,
  EMPLOYEE_PORTAL_HOURS_BOOKED_ROUTE,
  EMPLOYEE_PORTAL_LIST_ABSENCES_ROUTE,
  EMPLOYEE_PORTAL_LIST_VACATIONS_ROUTE,
  EMPLOYEE_PORTAL_MATERIAL_ROUTE,
  EMPLOYEE_PORTAL_RECOGNITIONS_ROUTE,
  EMPLOYEE_PORTAL_REQUEST_VACATIONS_ROUTE,
  EMPLOYEE_PORTAL_WORK_CALENDAR_ROUTE,
} from 'src/app/employee-portal/employee-portal-routing.module';
import { RoutePaths } from '../constants';
import { AuthStorageService } from '../services/auth.storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private readonly _router: Router,
    private readonly _authStorage: AuthStorageService
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const permissions: string[] = this._authStorage.permisos;
    // Remove the "/" from the URL
    const finalRoute = state.url?.slice(1);
    switch (finalRoute) {
      case EMPLOYEE_PORTAL_COURSES_ROUTE:
        if (permissions?.find((permission: string) => permission === 'CUR')) {
          return true;
        } else {
          this._router.navigate([RoutePaths.NotAllowed]);
          return false;
        }
      case EMPLOYEE_PORTAL_HOURS_BOOKED_ROUTE ||
        EMPLOYEE_PORTAL_LIST_ABSENCES_ROUTE:
        if (permissions?.find((permission: string) => permission === 'PRE')) {
          return true;
        } else {
          this._router.navigate([RoutePaths.NotAllowed]);
          return false;
        }
      case EMPLOYEE_PORTAL_LIST_VACATIONS_ROUTE ||
        EMPLOYEE_PORTAL_REQUEST_VACATIONS_ROUTE:
        if (permissions?.find((permission: string) => permission === 'VAC')) {
          return true;
        } else {
          this._router.navigate([RoutePaths.NotAllowed]);
          return false;
        }
      case EMPLOYEE_PORTAL_MATERIAL_ROUTE:
        if (permissions?.find((permission: string) => permission === 'MAT')) {
          return true;
        } else {
          this._router.navigate([RoutePaths.NotAllowed]);
          return false;
        }
      case EMPLOYEE_PORTAL_RECOGNITIONS_ROUTE:
        if (permissions?.find((permission: string) => permission === 'REC')) {
          return true;
        } else {
          this._router.navigate([RoutePaths.NotAllowed]);
          return false;
        }
      case EMPLOYEE_PORTAL_WORK_CALENDAR_ROUTE:
        if (permissions?.find((permission: string) => permission === 'CAL')) {
          return true;
        } else {
          this._router.navigate([RoutePaths.NotAllowed]);
          return false;
        }
      case EMPLOYEE_PORTAL_CONTACT_ROUTE:
        if (permissions?.find((permission: string) => permission === 'CON')) {
          return true;
        } else {
          this._router.navigate([RoutePaths.NotAllowed]);
          return false;
        }
      case EMPLOYEE_PORTAL_FICHAR_ROUTE ||
        EMPLOYEE_PORTAL_HISTORICO_FICHAJE_ROUTE:
        if (permissions?.find((permission: string) => permission === 'FIC')) {
          return true;
        } else {
          this._router.navigate([RoutePaths.NotAllowed]);
          return false;
        }
    }
    return true;
  }
}
