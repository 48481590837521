import {ColumnTypes, TableHeaders} from 'ekiba-master-table';

export const TABLE_HEADERS_MATERIAL: TableHeaders[] = [
  {
    name: 'Fecha Hora Entrega',
    displayName: 'Fecha/Hora Entrega',
    type: ColumnTypes.Date,
  },
  {
    name: 'Producto',
    displayName: 'Productos',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Descripción',
    displayName: 'Descripcións',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Curso',
    displayName: 'Cursos',
    type: ColumnTypes.Standard,
  },
  {
    name: 'DescCurso',
    displayName: 'Descripcion curso',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Cantidad',
    displayName: 'Cantidads',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Entregado Por',
    displayName: 'Entregado Pors',
    type: ColumnTypes.Standard,
  },
];

