<div class="hours-graph-container">
  <mat-card-title>Fichaje</mat-card-title>
  <hr class="hr-title" />
  <mat-card-subtitle
    >Haga click en Entrada / Salida para realizar un fichaje o un fichaje de
    ausencia</mat-card-subtitle
  >
  <div class="fichaje-buttons-container">
    <button
      class="fichaje-buttons-container__button primary"
      mat-raised-button
      (click)="ficharEntradaOInicioAusencia(0)"
    >
      <p class="fichaje-buttons-container__button__header">Entrada</p>
      <p class="fichaje-buttons-container__button__body">
        {{ datosFichaje?.entrada | number }}
      </p>
    </button>
    <button
      class="fichaje-buttons-container__button"
      mat-raised-button
      color="warn"
      (click)="ficharSalidaOFinAusencia(0)"
    >
      <p class="fichaje-buttons-container__button__header">Salida</p>
      <p class="fichaje-buttons-container__button__body">
        {{ datosFichaje?.salida | number }}
      </p>
    </button>
    <button
      class="fichaje-buttons-container__button secondary"
      mat-raised-button
      (click)="getCodAusenciasList('entrada')"
    >
      <p class="fichaje-buttons-container__button__header">Inicio Ausencia</p>
      <p class="fichaje-buttons-container__button__body">
        {{ datosFichaje?.inicioAusencia | number }}
      </p>
    </button>
    <button
      class="fichaje-buttons-container__button dark"
      mat-raised-button
      (click)="ficharSalidaOFinAusencia(1)"
    >
      <p class="fichaje-buttons-container__button__header">Fin Ausencia</p>
      <p class="fichaje-buttons-container__button__body">
        {{ datosFichaje?.finAusencia | number }}
      </p>
    </button>
    <button
      class="fichaje-buttons-container__capacity-box"
      mat-button
      *ngIf="datosFichaje?.horasCapacidad"
    >
      <p class="fichaje-buttons-container__capacity-box__header">
        Horas Capacidad Semana
      </p>
      <p class="fichaje-buttons-container__capacity-box__body">
        {{ datosFichaje?.horasCapacidad | number }}
      </p>
    </button>
    <button
      class="fichaje-buttons-container__capacity-box"
      mat-button
      *ngIf="datosFichaje?.horasPresencia"
    >
      <p class="fichaje-buttons-container__capacity-box__header">
        Horas Presencia Semana
      </p>
      <p class="fichaje-buttons-container__capacity-box__body">
        {{ datosFichaje?.horasPresencia | number }}
      </p>
    </button>
  </div>
  <mat-spinner class="spinner" *ngIf="spinner"></mat-spinner>
</div>
