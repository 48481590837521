import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { throwError } from 'rxjs/internal/observable/throwError';
import { filter } from 'rxjs/internal/operators/filter';
import { finalize } from 'rxjs/internal/operators/finalize';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError } from 'rxjs/operators';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import { LoadingOverlayComponent } from 'src/app/shared/components/loading-overlay/loading-overlay.component';
import { Action } from 'src/app/shared/interfaces';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { EmployeePortalStorageService } from '../../employee-portal.storage.service';
import {
  CodAusenciasListComponent,
  CodAusenciasListPopupOutput,
} from './cod-ausencias-list/cod-ausencias-list.component';
import {
  DetalleFichajeInput,
  FichajeCall,
  FichajeInput,
  ResponseCall,
} from './fichaje.call';

export interface DatosFichaje {
  entrada: number;
  salida: number;
  inicioAusencia: number;
  finAusencia: number;
  horasCapacidad?: number;
  horasPresencia?: number;
}

@Component({
  selector: 'app-fichaje',
  templateUrl: './fichaje.component.html',
  styleUrls: ['./fichaje.component.scss'],
})
export class FichajeComponent implements OnInit {
  public spinner: boolean = false;
  public datosFichaje: DatosFichaje;

  constructor(
    private readonly _fichajeCall: FichajeCall,
    private readonly _authStorageService: AuthStorageService,
    private readonly _employeePortalStorageService: EmployeePortalStorageService,
    private readonly _dialog: MatDialog,
    private readonly _errorHandler: ErrorHandlerService
  ) {
    this._getDetalleFichaje();
  }

  public ngOnInit(): void {}

  public ficharEntradaOInicioAusencia(
    tipo: number,
    codAusencia?: string
  ): void {
    this.spinner = true;
    const fichajeInput: FichajeInput = {
      pUserId: this._authStorageService.getDataSaved().pUserId,
      pTipo: tipo,
      pDateTime: new Date().toISOString(),
    };
    if (codAusencia) fichajeInput.pCodAusencia = codAusencia;
    this._fichajeCall
      .postEntradaOInicioAusencia(fichajeInput)
      .pipe(
        tap((res: any) => {
          if (res?.value) {
            if (tipo === 0) {
              this.datosFichaje.entrada++;
            } else {
              this.datosFichaje.inicioAusencia++;
            }
          }
        }),
        catchError((err) =>
          throwError(this._errorHandler.error(err.error?.error?.message))
        ),
        finalize(() => (this.spinner = false))
      )
      .subscribe();
  }

  public ficharSalidaOFinAusencia(tipo: number): void {
    this.spinner = true;
    const fichajeAusenciaInput: FichajeInput = {
      pUserId: this._authStorageService.getDataSaved().pUserId,
      pTipo: tipo,
      pDateTime: new Date().toISOString(),
    };
    this._fichajeCall
      .postSalidaOFinAusencia(fichajeAusenciaInput)
      .pipe(
        tap((res: any) => {
          if (res?.value) {
            if (tipo === 0) {
              this.datosFichaje.salida++;
            } else {
              this.datosFichaje.finAusencia++;
            }
          }
        }),
        catchError((err) =>
          throwError(this._errorHandler.error(err.error?.error?.message))
        ),
        finalize(() => (this.spinner = false))
      )
      .subscribe();
  }

  public getCodAusenciasList(tipo: string) {
    this.spinner = true;
    this._fichajeCall
      .getListadosCodAusencia()
      .pipe(
        tap((arrayAusencias: any) => {
          this._openCodAusenciaList(JSON.parse(arrayAusencias?.value), tipo);
        }),
        catchError((err) =>
          throwError(this._errorHandler.error(err.error?.error?.message))
        ),
        finalize(() => (this.spinner = false))
      )
      .subscribe();
  }

  private _getDetalleFichaje() {
    const dialog$ = this._dialog.open(LoadingOverlayComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      disableClose: true,
      panelClass: 'hide',
    });
    const detalleFichajeInput: DetalleFichajeInput = {
      pUserId: this._authStorageService.getDataSaved().pUserId,
    };
    this._fichajeCall
      .getDetalleFichaje(detalleFichajeInput)
      .pipe(
        tap((arrayFichaje: ResponseCall) => {
          this._employeePortalStorageService.fichaje = arrayFichaje.value;
          this.datosFichaje = JSON.parse(
            this._employeePortalStorageService.fichaje
          );
          if (this.datosFichaje?.horasPresencia) {
            this.datosFichaje.horasPresencia = parseFloat(
              this.datosFichaje.horasPresencia.toFixed(2)
            );
          }
          if (this.datosFichaje?.horasCapacidad) {
            this.datosFichaje.horasCapacidad = parseFloat(
              this.datosFichaje.horasCapacidad.toFixed(2)
            );
          }
        }),
        catchError((err) =>
          throwError(this._errorHandler.error(err.error?.error?.message))
        ),
        finalize(() => {
          dialog$.close();
        })
      )
      .subscribe();
  }

  private _openCodAusenciaList(arrayAusencias: any, tipo: string): void {
    const dialog = this._dialog.open(CodAusenciasListComponent, {
      data: {
        codAusencias: arrayAusencias,
      },
      width: '500px',
    });

    dialog
      .afterClosed()
      .pipe(
        filter(
          (result: CodAusenciasListPopupOutput) =>
            result.event !== Action.cancel
        )
      )
      .subscribe((res: CodAusenciasListPopupOutput) => {
        if (tipo === 'entrada') {
          this.ficharEntradaOInicioAusencia(1, res.value);
        } else {
          this.ficharSalidaOFinAusencia(1);
        }
      });
  }
}
