import {Injectable} from '@angular/core';

@Injectable()
export class DateManagerService {
  /**
   * @returns dd/mm/yyyy
   */
  public getEuropeanFormat(genericDate: string|Date|number): string {
    const date: Date = this._formatToDate(genericDate);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  /**
   * @returns dd/mm/yy
   */
  public getEuropeanFormat2(genericDate: string|Date|number): string {
    const date: Date = this._formatToDate(genericDate);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear() - 2000}`;
  }

  /**
   * Returns a date matching the DATETIME type from SQL
   * It sets the hour to 00:00:00 on the database
   * @param genericDate is
   * @returns yyyy-m-dd
   */
  public getDateForDatabase(genericDate: string|Date|number): string {
    const date: Date = this._formatToDate(genericDate);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  /**
   * Returns a date matching the DATETIME type from SQL
   * It does take the hour into account
   * @param dateString is
   * @returns yyyy-m-dd hh:mm:ss
   */
  public getDateForDatabaseWithHour(dateString: string|Date|number): string {
    const date: Date = this._formatToDate(dateString);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.toLocaleTimeString()}`;
  }

  /**
   * @param dateString is
   * @returns mm/yyyy
   */
  public getDateByMonth(dateString?: string|Date|number): string {
    const date: Date = this._formatToDate(dateString);
    return `${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  /**
   * @param dateString is
   * @returns yyyy-mm
   */
  public getDateByMonth2(dateString?: string|Date|number): string {
    const date: Date = this._formatToDate(dateString);
    return `${date.getFullYear()}-${date.getMonth() + 1}`;
  }

  public getAbsoluteMonth(dateString?: string|Date|number): number {
    const date: Date = this._formatToDate(dateString);
    return this.getAbsoluteDate(`${date.getFullYear()}-${date.getMonth() + 1}`);
  }

  /**
   * Returns the date on ms at 00:00:00
   */
  public getAbsoluteDate(dateString?: string|Date|number|undefined): number {
    if (dateString === undefined) {
      dateString = new Date();
    }

    const date: Date = this._formatToDate(dateString);
    return new Date(date.toDateString()).getTime();
  }

  public getDate(dateString?: string|Date|number|undefined): Date {
    return new Date(this.getAbsoluteDate(dateString));
  }

  public getTime(dateString?: string|Date|number|undefined): Date {
    const date = this._formatToDate(dateString);
    return new Date(date.toDateString());
  }

  public geFullYear(dateString: string): string {
    const date: Date = this._formatToDate(dateString);
    return `${date.getFullYear()}`;
  }

  public calculateTimeDiff(dateString?: string|Date|number|undefined): number {
    const date = this._formatToDate(dateString);
    if (date.getTimezoneOffset() === -120) {
      return 60 * 60 * 1000;
    } else {
      return 0;
    }
  }

  private _formatToDate(date: string|Date|number): Date {
    return new Date(date);
  }
}
