import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {debounceTime, filter, takeUntil, tap} from 'rxjs/operators';
import {DateManagerService} from 'src/app/shared/services/date-manager.service';

import {ColumnTypes, MasterTableComponent, TableHeaders} from '../master-table/master-table.component';

export interface SsrFilter {
  filterObject?: object;
  pageEvent?: PageEvent;
  sort?: Sort;
}

@Component({
  selector: 'ekiba-master-table-ssr',
  templateUrl: '../master-table/master-table.component.html',
  styleUrls: ['../master-table/master-table.component.scss'],
})
export class MasterTableSsrComponent extends MasterTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator, {static: true})
  protected paginator: MatPaginator;

  @Input()
  public set totalCount(newValue: number) {
    if (!!!newValue || newValue === this._totalCount) {
      return;
    }
    this._totalCount = newValue;
  }
  public get totalCount(): number {
    return this._totalCount;
  }

  @Input()
  public set dataSource(newValue: any) {
    const _newValue = newValue.slice();
    if (!!_newValue && _newValue.length >= 100) {
      const keys = Object.keys(_newValue[0]);
      const pendingRows = this.totalCount - _newValue.length;
      for (let i = 0; i < pendingRows; i++) {
        const objectToInject: object = {};
        keys.forEach((key: string) => {
          if (this._sort.direction && this._sort.direction === 'asc') {
            const columnType: TableHeaders = this.columns.find((c: TableHeaders) => c.name === key) ||
                {displayName: 'default', type: ColumnTypes.Standard, name: 'default'};

            objectToInject[key] = columnType.type !== ColumnTypes.Standard ? new Date(0) : 'zzzzz';
          } else {
            objectToInject[key] = undefined;
          }
        });
        _newValue.push(objectToInject);
      }
    }

    if (this._dataSource?.filteredData && (!_newValue || _newValue[0]?.id !== this._dataSource.filteredData[0]?.id)) {
      this._visitedIndexes = [0];
      if (this.paginator) {
        this.paginator.pageIndex = 0;
      }
    }

    this._dataSource = new MatTableDataSource(_newValue);
    this._dataSource.sort = this.sort;

    if (this.showPaginator) {
      this._dataSource.paginator = this.paginator;
    }

    this._resetFormControls();
  }
  public get dataSource(): any {
    return this._dataSource;
  }

  @Input()
  public filterObject: object;

  @Output()
  public ssrFiltered = new EventEmitter<SsrFilter>();

  private _sort: Sort = {active: '', direction: ''};
  private _visitedIndexes: number[] = [0];
  private _paginatorStatus: PageEvent;
  private _totalCount;
  private _ssRTabledestroyed$ = new Subject();

  public constructor(
      protected readonly paginators: MatPaginatorIntl,
      protected readonly _dms: DateManagerService,
      protected readonly _dialog: MatDialog,
  ) {
    super(paginators, _dms, _dialog);

    // Due to the current implementation, there isn't a solution to solve server side pagination with this option already
    this.showFirstLastButtonsOfPaginator = false;

    this._paginatorStatus = {length: this._dataSource?.length || 100, pageIndex: 0, pageSize: 10};
  }

  public ngOnDestroy(): void {
    this._ssRTabledestroyed$.next();
    this._ssRTabledestroyed$.complete();
  }

  public ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
      this.paginator.page
          .pipe(
              filter(
                  (newValue: PageEvent) =>
                      (!this._visitedIndexes.some(v => v === Math.floor(((newValue.pageIndex * newValue.pageSize) / 100))))),
              tap((newValue: PageEvent) => {
                this._visitedIndexes.unshift(Math.floor(((newValue.pageIndex * newValue.pageSize) / 100)));
                this._paginatorStatus = newValue;
                this._reloadFilters();
              }),
              takeUntil(this._ssRTabledestroyed$))
          .subscribe();
    }
  }

  protected _initFormControls(): void {
    this.tableFormControls = this._recievedLabels.map(
        (label: string) =>
            new FormControl(this.filterObject && typeof this.filterObject[label] === 'string' ? this.filterObject[label] : ''));

    this.dateFormGroup = this._recievedLabels.map((label: string) => {
      const _startDate = this.filterObject && !!this.filterObject[label]?.startDate ? new Date(this.filterObject[label]?.startDate) : '';
      const _endDate = this.filterObject && !!this.filterObject[label]?.endDate ? new Date(this.filterObject[label]?.endDate) : '';
      return new FormGroup(
          {
            startDate: new FormControl(_startDate),
            endDate: new FormControl(_endDate),
          },
      );
    });

    this.tableFormControls.forEach((control: FormControl, index) => {
      control.valueChanges
          .pipe(
              takeUntil(this._ssRTabledestroyed$),
              filter((changedValue: string|Date) => this._filterChangesStandardFormControl(changedValue, index)),
              debounceTime(500),
              tap((changedValue: string|Date) => {
                this._filterValues[this._recievedLabels[index]] = changedValue;
                this.paginator.pageIndex = 0;
                this._paginatorStatus.pageIndex = 0;
                this._reloadFilters();
              }))
          .subscribe();
    });

    this.dateFormGroup.forEach((formGroup: FormGroup, index: number) => {
      formGroup.controls.endDate.valueChanges
          .pipe(
              takeUntil(this._ssRTabledestroyed$),
              filter((changedValue: Date|undefined) => this._filterChangesDateFormControl(changedValue, index)),
              tap((changedValue: Date|undefined) => {
                const {startDate} = this.dateFormGroup[index].value;
                this._filterValues[this._recievedLabels[index]] = {
                  startDate: startDate ? this._dms.getDateForDatabase(startDate) : '',
                  endDate: startDate ? this._dms.getDateForDatabase(changedValue) : '',
                };
                this.paginator.pageIndex = 0;
                this._paginatorStatus.pageIndex = 0;
                this._reloadFilters();
              }))
          .subscribe();
    });

    this.sort.sortChange
        .pipe(takeUntil(this._ssRTabledestroyed$), tap((newValue) => {
                this.paginator.pageIndex = 0;
                this._paginatorStatus.pageIndex = 0;
                this._visitedIndexes = [0];
                this._sort = newValue;
                this._reloadFilters();
              }))
        .subscribe();
  }

  protected _resetFormControls(): void {
    // Override
  }

  private _reloadFilters(): void {
    this.ssrFiltered.emit({filterObject: this._filterValues, pageEvent: this._paginatorStatus, sort: this._sort});
  }
}
