import {registerLocaleData} from '@angular/common';
import es from '@angular/common/locales/es';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {Chart, ChartConfiguration, ChartData, ChartType} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {BaseChartDirective} from 'ng2-charts';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError, finalize, tap} from 'rxjs/operators';
import {AuthStorageService} from 'src/app/auth/services/auth.storage.service';
import {EmployeePortalStorageService} from 'src/app/employee-portal/employee-portal.storage.service';
import {ErrorHandlerService} from 'src/app/shared/services/error-handler.service';

import {CalendarInput, HoursBookedCall, ResponseCall,} from './hours-booked.call';

@Component({
  templateUrl: './hours-booked.component.html',
  styleUrls: ['./hours-booked.component.scss'],
})
export class HoursBookedComponent implements OnInit {
  constructor(
      private readonly _employeePortalStorageService:
          EmployeePortalStorageService,
      private readonly _hoursBookedCall: HoursBookedCall,
      private readonly _authStorageService: AuthStorageService,
      private readonly _errorHandler: ErrorHandlerService) {}

  public get dateToShow(): string {
    if (!this._dateRequest) {
      return '';
    }
    return this._dateRequest;
  }

  public today: Date = new Date();
  public startDate: Date;
  public spinner = false;
  public year = new FormControl();
  public totalsHours = [];
  private _dateRequest: string;
  private _daysHoursWorkRegistered: string[] = [];
  private _daysHoursWorkReal: string[] = [];
  private _hourPerMonthRegostered: Array<number> = [
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ];
  private _hourPerMonthReal: Array<number> = [
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ];
  private _needUpdate = false;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective|undefined;
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        align: 'top',
        rotation: -45,
        anchor: 'end',
      },
    },
  };
  public barChartType: ChartType = 'bar';

  public barChartData: ChartData<'bar'> = {
    labels: [
      'ENE',
      'FEB',
      'MAR',
      'ABR',
      'MAY',
      'JUN',
      'JUL',
      'AGO',
      'SEP',
      'OCT',
      'NOV',
      'DIC',
    ],
    datasets:
        [
          {
            data: this._hourPerMonthRegostered,
            label: 'Horas registradas',
            backgroundColor: '#54C3EC',
            hoverBackgroundColor: '#3A8FAF',
          },
          {
            data: this._hourPerMonthReal,
            label: 'Horas reales',
            backgroundColor: '#2576A5',
            hoverBackgroundColor: '#C79A08',
          },
        ],
  };
  public ngOnInit(): void {
    this._dateRequest = `Año ${this.today.getFullYear()}`;
    this.calculateHoursDates();
    Chart.register(ChartDataLabels);
    registerLocaleData(es);
  }

  public makeValuesGraphic(yearSelected: number): void {
    if (this._needUpdate) {
      this.spinner = true;
      const dateStart: Date = new Date(yearSelected, 0, 2);
      const dateEnd: Date = new Date(yearSelected, 11, 32);
      const calendarInput: CalendarInput = {
        pInicio: dateStart.toISOString(),
        pFin: dateEnd.toISOString(),
        pUserId: this._authStorageService.getDataSaved().pUserId,
        pTipoCalendario: '0',
      };
      this._hourPerMonthRegostered = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this._hourPerMonthReal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this._hoursBookedCall.getWorkCalendarHours(calendarInput)
          .pipe(
              tap((arrayDates: ResponseCall) => {
                this._employeePortalStorageService.workDaysHoursBooked =
                    arrayDates.value;
                this.calculateHoursDates();
              }),
              catchError(
                  (err) => throwError(
                      this._errorHandler.error(err.error?.error?.message))),
              finalize(() => (this.spinner = false)))
          .subscribe(() => {});
    }
    this._needUpdate = false;
  }

  private calculateHoursDates(): void {
    this._daysHoursWorkReal =
        JSON.parse(this._employeePortalStorageService.workDaysHoursBooked)[0];
    this._daysHoursWorkRegistered =
        JSON.parse(this._employeePortalStorageService.workDaysHoursBooked)[1];
    const valuesDateRegistered = Object.values(this._daysHoursWorkRegistered);
    let index = 0;
    if (valuesDateRegistered.length) {
      Object.keys(this._daysHoursWorkRegistered).forEach((stringDate) => {
        const x = parseFloat(valuesDateRegistered[index]);
        const i = parseInt(stringDate.substring(3, 5), 10) - 1;
        this._hourPerMonthRegostered[i] =
            +(this._hourPerMonthRegostered[i] + x).toFixed(2);
        index++;
      });
      this.barChartData.datasets[0].data = this._hourPerMonthRegostered;
      this.totalsHours[0] =
          +this._hourPerMonthRegostered.reduce((a, b) => a + b, 0).toFixed(2);
    } else {
      this._hourPerMonthRegostered = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.barChartData.datasets[0].data = this._hourPerMonthRegostered;
      this.totalsHours[0] = 0;
    }

    const valuesDate = Object.values(this._daysHoursWorkReal);
    let zindex = 0;
    if (valuesDate.length) {
      Object.keys(this._daysHoursWorkReal).forEach((stringDate) => {
        const x = parseFloat(valuesDate[zindex]);
        const i = parseInt(stringDate.substring(3, 5), 10) - 1;
        this._hourPerMonthReal[i] = +(this._hourPerMonthReal[i] + x).toFixed(2);
        zindex++;
      });
      this.barChartData.datasets[1].data = this._hourPerMonthReal;
      this.totalsHours[1] =
          +this._hourPerMonthReal.reduce((a, b) => a + b, 0).toFixed(2);
    } else {
      this._hourPerMonthReal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.barChartData.datasets[1].data = this._hourPerMonthReal;
      this.totalsHours[1] = 0;
    }
    this.totalsHours[2] =
        +(this.totalsHours[1] - this.totalsHours[0]).toFixed(2);
  }

  public chosenYearHandler(
      normalizedMonth: Date, datepicker: MatDatepicker<Date>): void {
    this._dateRequest = `Año ${normalizedMonth.getFullYear()}`;
    datepicker.close();
    this._needUpdate = true;
    this.makeValuesGraphic(normalizedMonth.getFullYear());
  }
}
