import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public company: string;
  public proxyEnabled = false;

  constructor() {}
  // Gets a value of specified property in the configuration file
  public get(key: any) {
    return environment[key];
  }
  // Builds URL from partial path
  public url(path: string) {
    return `${this.get('baseUrl')}${this.get(path)}?Company=${this.company}`;
  }

  public urlWithoutCompany(path: string) {
    return `${this.get('baseUrl')}${this.get(path)}`;
  }
}
