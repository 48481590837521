import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CodAusenciasListPopupOutput {
  event: string;
  value: string;
}

@Component({
  selector: 'app-cod-ausencias-list',
  templateUrl: './cod-ausencias-list.component.html',
  styleUrls: ['./cod-ausencias-list.component.scss'],
})
export class CodAusenciasListComponent implements OnInit {
  public codAusencias: any[] = [];
  public selectedAusencia: string;

  constructor(
    public dialogRef: MatDialogRef<CodAusenciasListComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data?.codAusencias) {
      // Map BC response to readable array
      for (let value of Object.values(data?.codAusencias)) {
        let items: any = {};
        for (let [key, child] of Object.entries(value)) {
          items[key] = child;
        }
        this.codAusencias.push(items.Code.value);
      }
    }
  }

  ngOnInit(): void {}

  public closeDialog(): void {
    this.dialogRef.close({ event: 'Cancelar' });
  }

  public onSubmit(): void {
    this.dialogRef.close({ event: 'Confirmar', value: this.selectedAusencia });
  }
}
