import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { EkibaMasterTableModule } from 'ekiba-master-table';
import { DeleteTablePopupComponent } from 'ekiba-master-table/lib/pop-up/delete-table.popup.component';
import { NgChartsModule } from 'ng2-charts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { ButtonComponent } from './components/button/button.component';
import { CardButtonComponent } from './components/card-button/card-button.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { EkibaMatCardComponent } from './components/ekiba-mat-card/ekiba-mat-card.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { NavTableComponent } from './components/nav-table/nav-table.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AddTableComponent } from './components/table/add-table/add-table.component';
import { PickDatePopupComponent } from './components/table/add-table/pop-up/pick-date.component';
import { MasterTableDatesComponent } from './components/table/master-table-dates/master-table-dates.component';
import { MasterTableSsrComponent } from './components/table/master-table-ssr/master-table-ssr.component';
import { MasterTableComponent } from './components/table/master-table/master-table.component';
import { RequestTableComponent } from './components/table/request-table/request-table.component';
import { SingleSelectTableComponent } from './components/table/single-select-table/single-select-table.component';
import { StaffTableComponent } from './components/table/staff-table/staff-table.component';
import { WarningPopupComponent } from './components/warning/warning-popup.component';
import { SearchPipe } from './pipes/search.pipe';
import { DateManagerService } from './services/date-manager.service';

// import {EkibaNavbarModule} from 'ekiba-navbar';

@NgModule({
  declarations: [
    // Components
    AddTableComponent,
    ButtonComponent,
    CardButtonComponent,
    DropdownComponent,
    EkibaMatCardComponent,
    LoadingOverlayComponent,
    MasterTableComponent,
    MasterTableDatesComponent,
    MasterTableSsrComponent,
    NavbarComponent,
    NavTableComponent,
    PickDatePopupComponent,
    RequestTableComponent,
    SingleSelectTableComponent,
    StaffTableComponent,
    WarningPopupComponent,
    // Pipes
    SearchPipe,
  ],
  imports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // Material
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgChartsModule,
    NgxMatSelectSearchModule,
  ],
  providers: [DateManagerService],
  entryComponents: [
    DeleteTablePopupComponent,
    LoadingOverlayComponent,
    PickDatePopupComponent,
  ],
  exports: [
    // Material
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatLabel,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgChartsModule,
    NgxMatSelectSearchModule,

    // 3rd party
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,

    // EKIBA COMPONENTS
    EkibaMasterTableModule,
    // EkibaNavbarModule,

    // Components
    AddTableComponent,
    ButtonComponent,
    CardButtonComponent,
    DropdownComponent,
    EkibaMatCardComponent,
    MasterTableComponent,
    MasterTableDatesComponent,
    MasterTableSsrComponent,
    NavbarComponent,
    NavTableComponent,
    RequestTableComponent,
    SingleSelectTableComponent,
    StaffTableComponent,
    WarningPopupComponent,

    // Pipes
    SearchPipe,
  ],
})
export class SharedModule {}
