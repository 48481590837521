import {CellType, ColumnTypes, TableHeaders} from 'ekiba-master-table';

export const TABLE_HEADERS_VACATIONS: TableHeaders[] = [
  {
    name: 'Starting Date',
    displayName: 'Fecha Inicial',
    type: ColumnTypes.Date,
  },
  {
    name: 'Ending Date',
    displayName: 'Fecha Final',
    type: ColumnTypes.Date,
  },
  {
    name: 'Description',
    displayName: 'Descripción',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Num Dias Nat Hab',
    displayName: 'Dias Hábiles',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Estado',
    displayName: 'Estado.',
    type: ColumnTypes.Status,
  },
  {
    name: 'Motivo Cambio Estado',
    displayName: 'Motivo Cambio Estado.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Fecha Solicitud',
    displayName: 'Fecha Solicitud.',
    type: ColumnTypes.Date,
  },
];

export const CELL_TYPE: CellType = CellType.SmallCell;
