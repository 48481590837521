import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import { EmployeePortalStorageService } from 'src/app/employee-portal/employee-portal.storage.service';
import { LoadingOverlayComponent } from 'src/app/shared/components/loading-overlay/loading-overlay.component';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { CalendarInput, CoursesCall, ResponseCall } from './courses.call';

@Injectable({ providedIn: 'root' })
export class CoursesResolver implements Resolve<Observable<ResponseCall>> {
  public today: Date = new Date();

  public constructor(
    private readonly _dialog: MatDialog,
    private readonly _coursesCall: CoursesCall,
    private readonly _authStorageService: AuthStorageService,
    private readonly _employeePortalStorageService: EmployeePortalStorageService,
    private readonly _errorHandler: ErrorHandlerService
  ) {}

  public resolve(
    _activatedRoute: ActivatedRouteSnapshot
  ): Observable<ResponseCall> {
    const dialog$ = this._dialog.open(LoadingOverlayComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      disableClose: true,
      panelClass: 'hide',
    });

    const dateStart: Date = new Date(this.today.getFullYear(), 0, 2);
    const dateEnd: Date = new Date(this.today.getFullYear(), 11, 32);
    const calendarInput: CalendarInput = {
      pInicio: dateStart.toISOString(),
      pFin: dateEnd.toISOString(),
      pUserId: this._authStorageService.getDataSaved().pUserId,
    };

    const courses$ = this._coursesCall.getCourses(calendarInput).pipe(
      tap(
        (arrayDates: ResponseCall) =>
          (this._employeePortalStorageService.courses = arrayDates.value)
      ),
      catchError((err) =>
        throwError(this._errorHandler.error(err.error?.error?.message))
      )
    );

    return courses$.pipe(finalize(() => dialog$.close()));
  }
}
