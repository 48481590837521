import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WarningPopupComponent } from '../components/warning/warning-popup.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private _dialog: MatDialog) {}

  public error(message: string) {
    if (message) {
      this._dialog.open(WarningPopupComponent, {
        width: '800px',
        data: {
          headerMessage: '❌ Error ❌',
          errorMessage: message,
        },
      });
    }
    return message;
  }
}
