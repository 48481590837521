import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { StatusColorValue } from 'ekiba-master-table';
import { throwError } from 'rxjs/internal/observable/throwError';
import { finalize } from 'rxjs/internal/operators/finalize';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError } from 'rxjs/operators';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import {
  EmployeePortalStorageService,
  ListFichaje,
} from 'src/app/employee-portal/employee-portal.storage.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import {
  ListFichajeCall,
  ListFichajeInput,
  ResponseCall,
} from './list-fichaje.call';
import { CELL_TYPE, TABLE_HEADERS_FICHAJE } from './list-fichaje.constants';

@Component({
  selector: 'app-list-fichaje',
  templateUrl: './list-fichaje.component.html',
  styleUrls: ['./list-fichaje.component.scss'],
})
export class ListFichajeComponent implements OnInit {
  constructor(
    private readonly _employeePortalStorageService: EmployeePortalStorageService,
    private readonly _listFichajeCall: ListFichajeCall,
    private readonly _authStorageService: AuthStorageService,
    private readonly _errorHandler: ErrorHandlerService
  ) {}

  private _arrayFichaje: ListFichaje[];
  public columns = TABLE_HEADERS_FICHAJE;
  public CELL_TYPE = CELL_TYPE;

  public today: Date = new Date();
  public startDate: Date;
  public spinner = false;
  public year = new FormControl();
  public listFichajes = [];
  public statusColorsValues: StatusColorValue[] = [
    { class: 'blue-line', linkedValue: 'Presencia' },
    { class: 'grey-line', linkedValue: 'Ausencia' },
  ];
  private _dateRequest: string;
  private _needUpdate = false;

  public get dateToShow(): string {
    if (!this._dateRequest) {
      return '';
    }
    return this._dateRequest;
  }

  public ngOnInit(): void {
    this._dateRequest = `Año ${this.today.getFullYear()}`;
    this.makeTableValues();
  }

  public updateFichaje(yearSelected: number): void {
    if (this._needUpdate) {
      this.spinner = true;
      const dateStart: Date = new Date(yearSelected, 0, 2);
      const dateEnd: Date = new Date(yearSelected, 11, 32);
      const listFichajeInput: ListFichajeInput = {
        pInicio: dateStart.toISOString(),
        pFin: dateEnd.toISOString(),
        pUserId: this._authStorageService.getDataSaved().pUserId,
      };
      this._listFichajeCall
        .getListFichaje(listFichajeInput)
        .pipe(
          tap((arrayFichaje: ResponseCall) => {
            this._employeePortalStorageService.listFichaje = Object.values(
              JSON.parse(arrayFichaje.value)
            );
            this.makeTableValues();
          }),
          catchError((err) =>
            throwError(this._errorHandler.error(err.error?.error?.message))
          ),
          finalize(() => (this.spinner = false))
        )
        .subscribe();
    }
    this._needUpdate = false;
  }
  private makeTableValues(): void {
    this.listFichajes = [];
    this._arrayFichaje = this._employeePortalStorageService.listFichaje;
    for (const fichaje of Object.values(this._arrayFichaje)) {
      const abs = {
        'Tipo Linea': fichaje['Tipo Linea'].value,
        'Tipo Movimiento': fichaje['Tipo Movimiento'].value,
        'Cod Ausencia': fichaje['Cod Ausencia'].value,
        'Fecha Inicio': this.formatStringDate(fichaje['Fecha Inicio'].value),
        'Fecha Fin': this.formatStringDate(fichaje['Fecha Fin'].value),
        'Codigo Turno': fichaje['Codigo Turno'].value,
        'Tipo Incidencia': fichaje['Tipo Incidencia'].value,
        'Cdad Horas': fichaje['Cdad Horas'].value
          ? parseFloat(fichaje['Cdad Horas'].value)?.toFixed(2)
          : 0,
        Description: fichaje.Description.value,
        Recurso: fichaje.Recurso.value,
      };
      this.listFichajes.push(abs);
    }
  }

  public chosenYearHandler(
    normalizedMonth: Date,
    datepicker: MatDatepicker<Date>
  ): void {
    this._dateRequest = `Año ${normalizedMonth.getFullYear()}`;
    datepicker.close();
    this._needUpdate = true;
    this.updateFichaje(normalizedMonth.getFullYear());
  }

  public formatStringDate(dateString: string): string {
    if (dateString) {
      const stringDateStart = dateString.split('/');
      const dateStart =
        '20' +
        stringDateStart[2] +
        '-' +
        stringDateStart[1] +
        '-' +
        stringDateStart[0];
      return dateStart;
    }
    return '';
  }
}
