<mat-form-field appearance="outline">
  <mat-label>{{title}}</mat-label>
  <mat-select [formControl]="formMultiControl" [multiple]="multipleSelect" #multiSelect>
    <mat-option>
      <ngx-mat-select-search 
      [formControl]="formFilterControl"
      [showToggleAllCheckbox]="true" 
      [toggleAllCheckboxChecked]="objectIsChecked"
      [toggleAllCheckboxIndeterminate]="objectsIsIndeterminate"
      (toggleAll)="toggleSelectAll($event)"
      noEntriesFoundLabel="{{noEntriesFound}}"
      placeholderLabel="{{placeholder}}">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let dropdownElement of filteredArray | async" [value]="dropdownElement">
      {{dropdownElement.nombre}}
    </mat-option>
  </mat-select>
</mat-form-field>