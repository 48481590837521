<ng-container class="container-hours-booked" [ngSwitch]="spinner">
  <div class="hours-graph-container">
    <mat-card-title>Materiales</mat-card-title>
    <hr class="hr-title">
    <mat-card-subtitle>Listado de los materiales entregados
    </mat-card-subtitle>
    <br>
    <mat-form-field class="mat-form-dateYear" appearance="outline">
      <mat-icon matPreffix>today</mat-icon>
      <input class="input-year" matInput readonly [matDatepicker]="picker" [formControl]="year">
      <span class="year-span">{{dateToShow}}</span>
      <mat-datepicker-toggle class="datepiker-toggle-icon" matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearHandler($event, picker)">
      </mat-datepicker>
    </mat-form-field>
    <mat-spinner class="spinner" *ngSwitchCase="true"></mat-spinner>
    <ekiba-master-table *ngSwitchDefault
    [dataSource]="materials"
    [showDefaultActions]="false"
    [columns]="columns"
    [cellType]="cellType"
    [showFilters]="true"
    >
  </ekiba-master-table>
</div>
</ng-container>