import { Injectable } from '@angular/core';
import { CellType } from 'ekiba-master-table';

@Injectable({
  providedIn: 'root',
})
export class EmployeePortalStorageService {
  public absences: string;
  public courses: string;
  public holidais: string;
  public materials: string;
  public recognitions: string;
  public Svacations: SVacations[] = [];
  public listFichaje: ListFichaje[] = [];
  public vacations: Vacations[] = [];
  public workDays: string;
  public workDaysHoursBooked: string;
  public fichaje: string;
}

export interface Vacations {
  CodigoUnico: { caption: string; value: string };
  Description: { caption: string; value: string };
  'Ending Date': { caption: string; value: string };
  'Num Dias Nat Hab': { caption: string; value: string };
  Recurso: { caption: string; value: string };
  'Starting Date': { caption: string; value: string };
  Tipo: { caption: string; value: string };
}

export interface SVacations {
  Description: { caption: string; value: string };
  'Ending Date': { caption: string; value: string };
  Estado: { caption: string; value: string };
  'Fecha Solicitud': { caption: string; value: string };
  IdUnico: { caption: string; value: string };
  'Motivo Cambio Estado': { caption: string; value: string };
  'Nombre Recurso': { caption: string; value: string };
  'Num Dias Nat Hab': { caption: string; value: string };
  Recurso: { caption: string; value: string };
  'Resource Group No': { caption: string; value: string };
  'Starting Date': { caption: string; value: string };
}

export interface ListFichaje {
  'Tipo Linea': { caption: string; value: string };
  'Tipo Movimiento': { caption: string; value: string };
  'Cod Ausencia': { caption: string; value: string };
  'Fecha Inicio': { caption: string; value: string };
  'Fecha Fin': { caption: string; value: string };
  'Codigo Turno': { caption: string; value: string };
  'Tipo Incidencia': { caption: string; value: string };
  'Cdad Horas': { caption: string; value: string };
  Description: { caption: string; value: string };
  Recurso: { caption: string; value: string };
}

export interface Recognitions {
  'Centro Medico Nombre': { caption: string; value: string };
  'Centro Medico': { caption: string; value: string };
  'Enlace Web Resultados': { caption: string; value: string };
  'Factura Num': { caption: string; value: string };
  'Fecha Apto Condicionado': { caption: string; value: string };
  'Fecha Prevista': { caption: string; value: string };
  'Fecha Recepcion Docs': { caption: string; value: string };
  'Fecha Reconocimiento': { caption: string; value: string };
  'Fecha Renuncia': { caption: string; value: string };
  'Fecha Vto': { caption: string; value: string };
  'Origen Centro Medico': { caption: string; value: string };
  'Prefactura Num': { caption: string; value: string };
  'Producto Descrripcion': { caption: string; value: string };
  'Recurso Nombre': { caption: string; value: string };
  Característica: { caption: string; value: string };
  Comentarios: { caption: string; value: string };
  Facturable: { caption: string; value: string };
  Frecuencia: { caption: string; value: string };
  NumOrden: { caption: string; value: string };
  Producto: { caption: string; value: string };
  Recurso: { caption: string; value: string };
  Resultado: { caption: string; value: string };
}
export interface Course {
  'Fecha Inicio': { caption: string; value: string };
  'Fecha Fin': { caption: string; value: string };
  Situacion: { caption: string; value: string };
  Codigo: { caption: string; value: string };
  Descripcion: { caption: string; value: string };
  'Evaluacion Final': { caption: string; value: string };
  'Fecha Evaluacion': { caption: string; value: string };
  'Duracion Horas': { caption: string; value: string };
  Formador: { caption: string; value: string };
  'Nombre Centro Formacion': { caption: string; value: string };
  Lugar: { caption: string; value: string };
  'Horas Presenciales': { caption: string; value: string };
  'Horas Distancia': { caption: string; value: string };
  'Persona Evaluacion Formacion': { caption: string; value: string };
}
export interface Absences {
  Description: { caption: string; value: string };
  'Ending Date': { caption: string; value: string };
  IdUnico: { caption: string; value: string };
  'Num Dias Nat Hab': { caption: string; value: string };
  Recurso: { caption: string; value: string };
  'Starting Date': { caption: string; value: string };
  Tipo: { caption: string; value: string };
}
export interface Material {
  Cantidad: { caption: string; value: string };
  Curso: { caption: string; value: string };
  DescCurso: { caption: string; value: string };
  Descripción: { caption: string; value: string };
  'Entregado Por': { caption: string; value: string };
  'Fecha Hora Entrega': { caption: string; value: string };
  NombreRecurso: { caption: string; value: string };
  NombreRecursoEntrega: { caption: string; value: string };
  Producto: { caption: string; value: string };
  Recurso: { caption: string; value: string };
}

export const CELL_TYPE: CellType = CellType.SmallCell;
