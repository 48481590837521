import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, ValidatorFn} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ekiba-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})

export class DropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('multiSelect', {static: true})
  multiSelect: MatSelect;

  @Input()
  public title: string;

  @Input()
  public placeholder: string;

  @Input()
  public noEntriesFound: string;

  @Input()
  public multipleSelect: boolean;

  @Input()
  public arrayValidators: ValidatorFn[];

  @Input()
  public set selectedArray(newValues: DropdownElement[]) {
    if (newValues === this._selectedArray) {
      return;
    }
    const inputIsRight = newValues.every(value => !!value.id && !!value.nombre);
    if (!inputIsRight) {
      console.error(
          `Error when using: ekiba-dropdown. The @input [selectedArray] must match the DropdownElement[] interface. Wrong array: `,
          newValues);
      return;
    }
    this._selectedArray = newValues.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
    this.filteredArray.next(this._selectedArray);
  }
  public get selectedArray(): DropdownElement[] {
    return this._selectedArray;
  }

  @Output()
  changedValue = new EventEmitter<string>();

  public formFilterControl: FormControl = new FormControl();
  public formMultiControl: FormControl = new FormControl();
  public filteredArray: ReplaySubject<DropdownElement[]> = new ReplaySubject<DropdownElement[]>(1);
  public objectsIsIndeterminate = false;
  public objectIsChecked = false;

  private _selectedArray: DropdownElement[];
  private _filteredObjectsCache: DropdownElement[] = [];
  private _destroyed$ = new Subject();

  public constructor(
      private readonly _cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    if (this.arrayValidators?.length > 0) {
      this.formMultiControl.setValidators(this.arrayValidators);
    }
    this.formFilterControl.valueChanges.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this._filterObjects();
      this._setToggleAllCheckboxState();
    });

    this.formMultiControl.valueChanges.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this._setToggleAllCheckboxState();
      this.changedValue.emit(this.formMultiControl.value);
    });
  }

  public ngAfterViewInit(): void {
    this._setInitialValues();
  }
  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public toggleSelectAll(selectAllValue: boolean): void {
    this.filteredArray.pipe(take(1), takeUntil(this._destroyed$)).subscribe(val => {
      if (selectAllValue) {
        this.formMultiControl.patchValue(val);
      } else {
        this.formMultiControl.patchValue([]);
      }
      this._cdr.markForCheck();
    });
  }

  private _setToggleAllCheckboxState(): void {
    if (this.multipleSelect) {
      let filteredLength = 0;
      if (this.formMultiControl && this.formMultiControl.value) {
        this._filteredObjectsCache.forEach(el => {
          if (this.formMultiControl.value.indexOf(el) > -1) {
            filteredLength++;
          }
        });
      }
      this.objectsIsIndeterminate = filteredLength > 0 && filteredLength < this._filteredObjectsCache.length;
      this.objectIsChecked = filteredLength > 0 && filteredLength === this._filteredObjectsCache.length;
    }
  }

  private _setInitialValues(): void {
    this.filteredArray.pipe(take(1), takeUntil(this._destroyed$)).subscribe(() => {
      this.multiSelect.compareWith = (a: DropdownElement, b: DropdownElement) => a && b && a.id === b.id;
    });
  }

  private _filterObjects(): void {
    if (!this._selectedArray) {
      return;
    }

    let search = this.formFilterControl.value;
    if (!search) {
      this._filteredObjectsCache = this._selectedArray.slice();
      this.filteredArray.next(this._selectedArray.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this._filteredObjectsCache =
        this._selectedArray.filter((dropElement: DropdownElement) => dropElement.nombre.toLowerCase().indexOf(search) > -1);
    this.filteredArray.next(this._filteredObjectsCache);
  }
}

export interface DropdownElement {
  nombre: string;
  id: number;
}
