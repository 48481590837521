import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  template: '<mat-spinner class="spinner"></mat-spinner>',
  styles: [`
  .spinner {
    box-sizing: border-box;
    height: 100%;
    left: 50%;
    position: absolute;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }`],
})
export class LoadingOverlayComponent {
  public constructor(
      public dialogRef: MatDialogRef<LoadingOverlayComponent>,
  ) {
    dialogRef.disableClose = true;
  }
}
