import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '../auth/guards/authentication.guard';
import { AuthorizationGuard } from '../auth/guards/authorization.guard';
import { ContactComponent } from './dashboards/contact/contact.component';

import { CoursesComponent } from './dashboards/courses/courses.component';
import { CoursesResolver } from './dashboards/courses/courses.resolver';
import { FichajeComponent } from './dashboards/fichaje/fichaje.component';
import { ListFichajeComponent } from './dashboards/fichaje/list-fichaje/list-fichaje.component';
import { ListFichajeResolver } from './dashboards/fichaje/list-fichaje/list-fichaje.resolver';
import { MaterialsComponent } from './dashboards/material/material.component';
import { MaterialsResolver } from './dashboards/material/material.resolver';
import { HoursBookedComponent } from './dashboards/presence/hours-booked/hours-booked.component';
import { HoursBookedResolver } from './dashboards/presence/hours-booked/hours-booked.resolver';
import { ListAbsencesComponent } from './dashboards/presence/list-absences/list-absences.component';
import { ListAbsencesResolver } from './dashboards/presence/list-absences/list-absences.resolver';
import { RecognitionsComponent } from './dashboards/recognitions/recognitions.component';
import { RecognitionsResolver } from './dashboards/recognitions/recognitions.resolver';
import { ListVacationsComponent } from './dashboards/vacations/list-vacations/list-vacations.component';
import { ListVacationsResolver } from './dashboards/vacations/list-vacations/list-vacations.resolver';
import { RequestVacationsComponent } from './dashboards/vacations/request-vacations/request-vacations.component';
import { WorkCalendarComponent } from './dashboards/work-calendar/work-calendar.component';
import { WorkCalendarResolver } from './dashboards/work-calendar/work-calendar.resolver';
import { HomeComponent } from './home/home.component';

export const EMPLOYEE_PORTAL_COURSES_ROUTE = 'cursos';
export const EMPLOYEE_PORTAL_DEFAULT_ROUTE = 'inicio';
export const EMPLOYEE_PORTAL_HOURS_BOOKED_ROUTE = 'resumen-horas-fichadas';
export const EMPLOYEE_PORTAL_LIST_ABSENCES_ROUTE = 'listado-ausencias';
export const EMPLOYEE_PORTAL_LIST_VACATIONS_ROUTE = 'listado-vacaciones';
export const EMPLOYEE_PORTAL_MATERIAL_ROUTE = 'materiales';
export const EMPLOYEE_PORTAL_RECOGNITIONS_ROUTE = 'reconocimientos';
export const EMPLOYEE_PORTAL_REQUEST_VACATIONS_ROUTE = 'solicitud-vacaciones';
export const EMPLOYEE_PORTAL_WORK_CALENDAR_ROUTE = 'calendario-laboral';
export const EMPLOYEE_PORTAL_CONTACT_ROUTE = 'contacto';
export const EMPLOYEE_PORTAL_FICHAR_ROUTE = 'fichar';
export const EMPLOYEE_PORTAL_HISTORICO_FICHAJE_ROUTE = 'listado-fichaje';

// Needed to hide the navbar.
export const authRoutes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthenticationGuard] },
  {
    path: EMPLOYEE_PORTAL_DEFAULT_ROUTE,
    component: HomeComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
  },
  {
    path: EMPLOYEE_PORTAL_WORK_CALENDAR_ROUTE,
    component: WorkCalendarComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    resolve: [WorkCalendarResolver],
  },
  {
    path: EMPLOYEE_PORTAL_HOURS_BOOKED_ROUTE,
    component: HoursBookedComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    resolve: [HoursBookedResolver],
  },
  {
    path: EMPLOYEE_PORTAL_LIST_ABSENCES_ROUTE,
    component: ListAbsencesComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    resolve: [ListAbsencesResolver],
  },
  {
    path: EMPLOYEE_PORTAL_COURSES_ROUTE,
    component: CoursesComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    resolve: [CoursesResolver],
  },
  {
    path: EMPLOYEE_PORTAL_LIST_VACATIONS_ROUTE,
    component: ListVacationsComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    resolve: [ListVacationsResolver],
  },
  {
    path: EMPLOYEE_PORTAL_REQUEST_VACATIONS_ROUTE,
    component: RequestVacationsComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
  },
  {
    path: EMPLOYEE_PORTAL_RECOGNITIONS_ROUTE,
    component: RecognitionsComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    resolve: [RecognitionsResolver],
  },
  {
    path: EMPLOYEE_PORTAL_MATERIAL_ROUTE,
    component: MaterialsComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    resolve: [MaterialsResolver],
  },
  {
    path: EMPLOYEE_PORTAL_CONTACT_ROUTE,
    component: ContactComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
  },
  {
    path: EMPLOYEE_PORTAL_FICHAR_ROUTE,
    component: FichajeComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
  },
  {
    path: EMPLOYEE_PORTAL_HISTORICO_FICHAJE_ROUTE,
    component: ListFichajeComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    resolve: [ListFichajeResolver],
  },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class EmployeePortalRoutingModule {}
