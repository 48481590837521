export const environment = {
  production: false,
  footer: 'Portal del empleado de Grupo Tecon',
  user: 'Tecon01',
  password: 'P3n3l0p3@',
  urlLicencias: 'https://licencias.agenciaekiba.com',
  baseAppUrl: 'https://portalempleado.agenciaekiba.com',
  baseUrl: 'https://tcn-bc.westeurope.cloudapp.azure.com:7048/BC/ODataV4/',
  urlCalendar: 'FuncionesWsPre_CalendarioLaboralF',
  urlCourses: 'FuncionesWsRha_CursosRecursoF',
  urlHolidaysCalendar: 'FuncionesWsPre_DiasFestivosCalendarioF',
  urLListAbsences: 'FuncionesWsPre_ListadoAusenciasF',
  urlListVacations: 'FuncionesWsRha_ListadoSolicitudVacacionesF',
  urlLogin: 'FuncionesWsPre_DatosLoginF',
  urlMaterial: 'FuncionesWsRha_MaterialEntregadoF',
  urlRecognitions: 'FuncionesWsRha_ReconocimientoMedicoF',
  urlRequestVacationDays: 'FuncionesWsRha_NumDiasVacacionesF',
  urlRequestVacations: 'FuncionesWsRha_SolicitarVacacionesF',
  urlVacationsCalendar: 'FuncionesWsPre_ListadoVacacionesF',
  urlWorkCalendarBookedHours: 'FuncionesWsPre_HorasFichadasRealesTeoricasF',
  urlDetallesFichaje: 'FuncionesWsPre_DatosFichajeF',
  urlFicharEntrada: 'FuncionesWsPre_FicharEntradaOInicioAusenciaF',
  urlFicharSalida: 'FuncionesWsPre_FicharSalidaOFinAusenciaF',
  urlListadosCodAusencia: 'FuncionesWsPre_TiposAusenciasF',
  urlListadoAusenciasRecurso: 'FuncionesWsPre_ListadoAusenciasF',
  urlListFichaje: 'FuncionesWsPre_HcoFichajesF',
  urlEmpresas:
    'https://tcn-bc.westeurope.cloudapp.azure.com:7048/BC/ODataV4/FuncionesWsRha_EmpresasF',
  emailContacto: '',
};
