import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AES, enc} from 'crypto-js';

import {BehaviorSubject, Observable} from 'rxjs';
import {ConfigService} from 'src/app/shared/services/config.service';
import {RoutePaths} from '../constants';

import {LoginOutput} from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  constructor(
      private readonly _router: Router,
      private readonly _configService: ConfigService) {}

  public set company(c: string) {
    this._company = c;
    this._configService.company = c;
  }

  public get company(): string {
    return this._company;
  }

  public get permisos(): string[] {
    return this._permisos;
  }

  public set permisos(per: string[]) {
    this._permisos = per;
    const permisosEncrypted =
        AES.encrypt(JSON.stringify(per), this._configService.get('user'))
            .toString();
    localStorage.setItem('PERMISOS_PORTAL_DEL_EMPLEADO', permisosEncrypted);
    this.permisos$.next(per);
  }

  public permisos$: BehaviorSubject<string[]> =
      new BehaviorSubject<string[]>([]);

  private _loginOutputState: LoginOutput;
  private _loginOutput$ = new BehaviorSubject<LoginOutput>(undefined);
  private _permisos: string[] = [];
  private _company: string = '';

  public getLoginOutput$(): Observable<LoginOutput> {
    return this._loginOutput$.asObservable();
  }

  public setLoginOutput(loginOutput: LoginOutput): void {
    this._loginOutput$.next(loginOutput);
  }

  public saveDataLogin(loginOutput: LoginOutput): void {
    console.log('loginOutput');
    console.log(JSON.parse(loginOutput.value).recursos[0].nombre);

    localStorage.setItem('USER_PORTAL_DEL_EMPLEADO', '' + loginOutput.pUserId);
    localStorage.setItem(
        'NAME_PORTAL_DEL_EMPLEADO',
        '' + JSON.parse(loginOutput.value).recursos[0].nombre);
    localStorage.setItem(
        'TOKEN_PORTAL_DEL_EMPLEADO', '' + loginOutput.EncodeKeyAuth);

    const encryptedExpiryDate = AES.encrypt(
                                       JSON.stringify(loginOutput.expiryDate),
                                       this._configService.get('user'))
                                    .toString();
    localStorage.setItem(
        'FECHA_CADUCIDAD_PORTAL_DEL_EMPLEADO', encryptedExpiryDate);
    const company = AES.encrypt(
                           JSON.stringify(loginOutput.company),
                           this._configService.get('user'))
                        .toString();
    localStorage.setItem('EMPRESA_PORTAL_DEL_EMPLEADO', company);
    console.log(
        '%c Token successfully stored! ', 'background: #222; color: #bada55');
    this._loginOutputState = loginOutput;
  }

  public removeDataLogin(): void {
    this._loginOutputState.pUserId = null;
    this._loginOutputState.b64KeyAuth.Authorization = null;
    localStorage.removeItem('TOKEN_PORTAL_DEL_EMPLEADO');
    localStorage.removeItem('USER_PORTAL_DEL_EMPLEADO');
    localStorage.removeItem('FECHA_CADUCIDAD_PORTAL_DEL_EMPLEADO');
    localStorage.removeItem('PERMISOS_PORTAL_DEL_EMPLEADO');
    localStorage.removeItem('NAME_PORTAL_DEL_EMPLEADO');
    this._router.navigate([RoutePaths.Login]);
  }

  public getDataSaved(): LoginOutput {
    let loginOutput: LoginOutput = {
      pUserId: '',
      b64KeyAuth: {Authorization: ''},
      value: '{}',
    };
    loginOutput.pUserId = this.getUserData();
    loginOutput.b64KeyAuth.Authorization = this.getKeyData();
    this._loginOutputState = loginOutput;
    return loginOutput;
  }
  public getKeyData(): string {
    if (localStorage.getItem('TOKEN_PORTAL_DEL_EMPLEADO')) {
      const key = AES.decrypt(
                         localStorage.getItem('TOKEN_PORTAL_DEL_EMPLEADO'),
                         this._configService.get('user'))
                      .toString(enc.Utf8)
                      .split(';')[0];
      return key;
    }
    return localStorage.getItem('TOKEN_PORTAL_DEL_EMPLEADO');
  }

  public getUserData(): any {
    if (localStorage.getItem('TOKEN_PORTAL_DEL_EMPLEADO')) {
      const key = AES.decrypt(
                         localStorage.getItem('TOKEN_PORTAL_DEL_EMPLEADO'),
                         this._configService.get('user'))
                      .toString(enc.Utf8)
                      .split(';')[1];
      return key;
    }
    return localStorage.getItem('USER_PORTAL_DEL_EMPLEADO');
  }

  public getUserName(): any {
    return localStorage.getItem('NAME_PORTAL_DEL_EMPLEADO');
  }

  public getExpiryDateData(): any {
    if (localStorage.getItem('FECHA_CADUCIDAD_PORTAL_DEL_EMPLEADO')) {
      const key =
          AES.decrypt(
                 localStorage.getItem('FECHA_CADUCIDAD_PORTAL_DEL_EMPLEADO'),
                 this._configService.get('user'))
              .toString(enc.Utf8);
      return key;
    }
    return localStorage.getItem('FECHA_CADUCIDAD_PORTAL_DEL_EMPLEADO');
  }

  public getPermisosData(): any {
    if (localStorage.getItem('PERMISOS_PORTAL_DEL_EMPLEADO')) {
      const key = AES.decrypt(
                         localStorage.getItem('PERMISOS_PORTAL_DEL_EMPLEADO'),
                         this._configService.get('user'))
                      .toString(enc.Utf8);
      return key;
    }
    return localStorage.getItem('PERMISOS_PORTAL_DEL_EMPLEADO') || [];
  }

  public getCompanyData(): any {
    if (localStorage.getItem('EMPRESA_PORTAL_DEL_EMPLEADO')) {
      const key = AES.decrypt(
                         localStorage.getItem('EMPRESA_PORTAL_DEL_EMPLEADO'),
                         this._configService.get('user'))
                      .toString(enc.Utf8);
      return key;
    }
    return localStorage.getItem('EMPRESA_PORTAL_DEL_EMPLEADO');
  }
}
