import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import {
  CELL_TYPE,
  EmployeePortalStorageService,
  Material,
} from 'src/app/employee-portal/employee-portal.storage.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';

import { CalendarInput, MaterialCall, ResponseCall } from './material.call';
import { TABLE_HEADERS_MATERIAL } from './material.constants';

@Component({
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss'],
})
export class MaterialsComponent implements OnInit {
  constructor(
    private readonly _employeePortalStorageService: EmployeePortalStorageService,
    private readonly _materialCall: MaterialCall,
    private readonly _authStorageService: AuthStorageService,
    private readonly _errorHandler: ErrorHandlerService
  ) {}

  private _arrayMaterials: Material[];
  public columns = TABLE_HEADERS_MATERIAL;

  public today: Date = new Date();
  public startDate: Date;
  public spinner = false;
  public year = new FormControl();
  public materials = [];
  public cellType = CELL_TYPE;
  private _dateRequest: string;
  private _needUpdate = false;

  public get dateToShow(): string {
    if (!this._dateRequest) {
      return '';
    }
    return this._dateRequest;
  }

  public ngOnInit(): void {
    this._dateRequest = `Año ${this.today.getFullYear()}`;
    this.makeTableValues();
  }

  public updateMaterial(yearSelected: number): void {
    if (this._needUpdate) {
      this.spinner = true;
      const dateStart: Date = new Date(yearSelected, 0, 2);
      const dateEnd: Date = new Date(yearSelected, 11, 32);
      const calendarInput: CalendarInput = {
        pInicio: dateStart.toISOString(),
        pFin: dateEnd.toISOString(),
        pUserId: this._authStorageService.getDataSaved().pUserId,
      };
      this._materialCall
        .getMaterials(calendarInput)
        .pipe(
          tap((arrayMaterials: ResponseCall) => {
            this._employeePortalStorageService.materials = arrayMaterials.value;
            this.makeTableValues();
          }),
          catchError((err) =>
            throwError(this._errorHandler.error(err.error?.error?.message))
          ),
          finalize(() => (this.spinner = false))
        )
        .subscribe();
    }
    this._needUpdate = false;
  }
  private makeTableValues(): void {
    this.materials = [];
    this._arrayMaterials = JSON.parse(
      this._employeePortalStorageService.materials
    );
    for (const material of Object.values(this._arrayMaterials)) {
      const mtr = {
        'Entregado Por': material['Entregado Por'].value,
        'Fecha Hora Entrega': material['Fecha Hora Entrega'].value,
        Cantidad: material.Cantidad.value,
        Curso: material.Curso.value,
        DescCurso: material.DescCurso.value,
        Descripción: material.Descripción.value,
        Producto: material.Producto.value,
      };
      this.materials.push(mtr);
    }
  }

  public chosenYearHandler(
    normalizedMonth: Date,
    datepicker: MatDatepicker<Date>
  ): void {
    this._dateRequest = `Año ${normalizedMonth.getFullYear()}`;
    datepicker.close();
    this._needUpdate = true;
    this.updateMaterial(normalizedMonth.getFullYear());
  }

  public formatStringDate(dateString: string): string {
    const stringDateStart = dateString.split('/');
    const dateStart =
      '20' +
      stringDateStart[2] +
      '-' +
      stringDateStart[1] +
      '-' +
      stringDateStart[0];
    return dateStart;
  }
}
