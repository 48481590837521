import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import { ConfigService } from 'src/app/shared/services/config.service';

export interface DetalleFichajeInput {
  pUserId: string;
}
export interface FichajeInput {
  pUserId: string;
  pTipo: number;
  pDateTime: string;
  pCodAusencia?: string;
}
export interface ResponseCall {
  '@odata.context': string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class FichajeCall {
  private readonly _getDetalleFichaje =
    this._configService.url('urlDetallesFichaje');
  private readonly _getListadosCodAusencia = this._configService.url(
    'urlListadosCodAusencia'
  );
  private readonly _postEntradaOInicioAusencia =
    this._configService.url('urlFicharEntrada');
  private readonly _postSalidaOFinAusencia =
    this._configService.url('urlFicharSalida');

  public constructor(
    private readonly _authStorageService: AuthStorageService,
    private readonly _http: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  public getDetalleFichaje(
    fichajeInput: DetalleFichajeInput
  ): Observable<ResponseCall> {
    const bodyPost = {
      pParam: '{"pUserId": "' + fichajeInput.pUserId + '"}',
    };
    const option = {
      Authorization:
        this._authStorageService.getDataSaved().b64KeyAuth.Authorization,
    };
    return this._http.post<ResponseCall>(this._getDetalleFichaje, bodyPost, {
      headers: option,
    });
  }

  public getListadosCodAusencia(): Observable<ResponseCall> {
    const bodyPost = {
      pParam: '',
    };
    const option = {
      Authorization:
        this._authStorageService.getDataSaved().b64KeyAuth.Authorization,
    };
    return this._http.post<ResponseCall>(
      this._getListadosCodAusencia,
      bodyPost,
      {
        headers: option,
      }
    );
  }

  public postEntradaOInicioAusencia(
    fichajeInput: FichajeInput
  ): Observable<ResponseCall> {
    const bodyPost = {
      pParam:
        '{"pUserId": "' +
        fichajeInput.pUserId +
        '", "pTipo": ' +
        fichajeInput.pTipo +
        ', "pDateTime": "' +
        fichajeInput.pDateTime +
        '",' +
        (fichajeInput.pCodAusencia
          ? '"pCodAusencia": "' + fichajeInput.pCodAusencia + '"'
          : '') +
        '}',
    };
    const option = {
      Authorization:
        this._authStorageService.getDataSaved().b64KeyAuth.Authorization,
    };
    return this._http.post<ResponseCall>(
      this._postEntradaOInicioAusencia,
      bodyPost,
      {
        headers: option,
      }
    );
  }

  public postSalidaOFinAusencia(
    fichajeInput: FichajeInput
  ): Observable<ResponseCall> {
    const bodyPost = {
      pParam:
        '{"pUserId": "' +
        fichajeInput.pUserId +
        '", "pTipo": ' +
        fichajeInput.pTipo +
        ', "pDateTime": "' +
        fichajeInput.pDateTime +
        '"}',
    };
    const option = {
      Authorization:
        this._authStorageService.getDataSaved().b64KeyAuth.Authorization,
    };
    return this._http.post<ResponseCall>(
      this._postSalidaOFinAusencia,
      bodyPost,
      {
        headers: option,
      }
    );
  }
}
