import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { RoutePaths } from '../constants';
import { AuthStorageService } from '../services/auth.storage.service';
import {
  AuthenticationService,
  LoginInput,
  LoginOutput,
} from '../services/authentication.service';

export interface Company {
  name: string;
  value: string;
}

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public errorMesssage: string;
  public defaultErrorMessage = false;
  public spinner = true;
  public hide = true;
  public companies: Company[] = [];

  constructor(
    private readonly _activateRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _authenticationService: AuthenticationService,
    private readonly _authStorageService: AuthStorageService
  ) {}

  public ngOnInit(): void {
    if (
      this._authStorageService.getKeyData() &&
      this._authStorageService.getUserData()
    ) {
      this._router.navigate([RoutePaths.Home]);
    } else {
      this.spinner = false;
      this.buildForm();
    }
  }

  public buildForm(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', [
        Validators.maxLength(250),
        Validators.required,
      ]),
      password: new FormControl('', [
        Validators.maxLength(250),
        Validators.required,
      ]),
    });
  }

  public login(): void {
    this.spinner = true;
    const { username, password } = this.loginForm.value;
    const loginInput: LoginInput = {
      pUserId: username,
      pPassword: password,
    };
    this._authenticationService
      .login(loginInput)
      .pipe(
        tap((loginOutput: LoginOutput) => {
          this._manageAuthenticationErrors(loginOutput);
        }),
        finalize(() => (this.spinner = false))
      )
      .subscribe();
  }

  private _manageAuthenticationErrors(loginOutput: LoginOutput): void {
    const value = JSON.parse(loginOutput.value);
    if (loginOutput.value === '{}') {
      this.defaultErrorMessage = true;
      if (!value?.recursos[0]?.nombre || !value?.recursos[0]?.numLicencia) {
        this.errorMesssage = 'Este usuario no dispone de una licencia válida';
      }
      if (
        !loginOutput.errorMessage &&
        !value?.recursos[0]?.nombre &&
        !value?.recursos[0]?.numLicencia
      ) {
        this.errorMesssage = 'Los datos introducidos no son correctos';
      } else {
        this.errorMesssage = `Problema interno: ${loginOutput.errorMessage}`;
      }
    } else {
      const params = {
        uuid: '*',
        clave: value.recursos[0]?.numLicencia,
      };
      this._authenticationService
        .checkLicencia(params)
        .pipe(
          tap((licenciaOutput: any) => {
            if (licenciaOutput?.type !== 'error' && !licenciaOutput?.mensaje) {
              loginOutput.expiryDate = licenciaOutput.fecha_fin;
              this._authenticationService.checkLoginUser(loginOutput);
              this._setLoginOutput(loginOutput);
              this._authStorageService.permisos = licenciaOutput.permisos;

              this._router.navigate(['..', RoutePaths.Home], {
                relativeTo: this._activateRoute,
              });
            } else {
              if (licenciaOutput?.mensaje) {
                this.errorMesssage = licenciaOutput?.mensaje;
              } else {
                this.errorMesssage =
                  'Este usuario no dispone de una licencia válida';
              }
            }
          })
        )
        .subscribe();
    }
  }

  private _setLoginOutput(loginOutput: LoginOutput): void {
    this._authStorageService.setLoginOutput(loginOutput);
  }
}
