import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DateManagerService} from '../../services/date-manager.service';

export interface SelectedDates {
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'ekiba-nav-table',
  templateUrl: './nav-table.component.html',
  styleUrls: ['./nav-table.component.scss'],
})
export class NavTableComponent {
  private _wordInput: string;
  private _dateFilterStart: Date;
  private _dateFilterEnd: Date;
  public monthYear: string;

  @Input()
  public addItemName: string;

  @Input()
  public goBackName: string;

  @Input()
  public showFilterDate: boolean = false;

  @Input()
  public showFilter: boolean = true;

  @Input()
  public get wordInput(): string {
    return this._wordInput;
  }
  public set wordInput(val) {
    this._wordInput = val;
    this.wordInputChange.emit(this._wordInput);
  }

  @Input()
  public get dateFilterStart(): Date {
    return this._dateFilterStart;
  }
  public set dateFilterStart(val) {
    this._dateFilterStart = val;
  }

  @Input()
  public get dateFilterEnd(): Date {
    return this._dateFilterEnd;
  }
  public set dateFilterEnd(val) {
    if (val === null) {
      return;
    }
    this._dateFilterEnd = val;
    this.monthYear = val ? `${this._dms.getEuropeanFormat(this._dateFilterStart)} - ${this._dms.getEuropeanFormat(val)}` : '';

    if (val === undefined) {
      this.dateFilterChange.emit(undefined);
      return;
    }

    const selectedDates: SelectedDates = {
      endDate: this._dms.getDateForDatabase(this._dateFilterEnd),
      startDate: this._dms.getDateForDatabase(this._dateFilterStart),
    };
    this.dateFilterChange.emit(selectedDates);
  }

  @Output()
  public wordInputChange = new EventEmitter<string>();

  @Output()
  public dateFilterChange = new EventEmitter<SelectedDates>();

  @Output()
  public addedItem = new EventEmitter<any>();

  @Output()
  public wentBack = new EventEmitter<any>();

  public constructor(private readonly _dms: DateManagerService) {}

  public addItem(): void {
    this.addedItem.emit();
  }

  public clearDate(): void {
    this.dateFilterStart = undefined;
    this.dateFilterEnd = undefined;
  }

  public goBack(): void {
    this.wentBack.emit();
  }
}
