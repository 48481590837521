<ng-container [ngSwitch]="spinner">
  <mat-spinner class="spinner" *ngSwitchCase="true"></mat-spinner>
  <div *ngSwitchDefault class="login-container">
    <div class="logo"></div>
    <div class="body">
      <mat-card class="mat-elevation-z8 login-card">
        <h1>Portal del Empleado</h1>
        <span *ngIf="defaultErrorMesssage" class="mat-subheading-2 error-color">
          No se ha podido hacer el login
        </span>
        <span *ngIf="errorMesssage" class="mat-subheading-2 error-color">
          {{ errorMesssage }}
        </span>
        <form [formGroup]="loginForm" class="login-form">
          <div class="form-group">
            <div class="user">
              <mat-form-field class="input-form" appearance="fill">
                <mat-label>Usuario</mat-label>
                <mat-icon matPrefix>person</mat-icon>
                <input
                  type="text"
                  matInput
                  autocomplete="off"
                  formControlName="username"
                />
              </mat-form-field>
            </div>
          </div>
          <br />
          <div class="form-group">
            <div class="password">
              <mat-form-field class="input-form" appearance="fill">
                <mat-label>Contraseña</mat-label>
                <mat-icon matPrefix>key</mat-icon>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  formControlName="password"
                />
                <a
                  mat-icon-button
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                </a>
              </mat-form-field>
            </div>
          </div>
          <mat-checkbox *ngIf="false" class="checkbox-remember"
            >Recuérdame</mat-checkbox
          >
          <ng-container [ngSwitch]="loginForm.valid">
            <button
              *ngSwitchCase="true"
              mat-raised-button
              color="accent"
              (click)="login()"
              type="submit"
              class="button-login"
            >
              Acceder
            </button>
            <button
              *ngSwitchDefault
              disabled
              mat-raised-button
              color="accent"
              class="button-login"
            >
              Acceder
            </button>
          </ng-container>
        </form>
      </mat-card>
      <br />
    </div>
  </div>
</ng-container>
