import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutePaths } from './constants';
import { LoginComponent } from './login/login.component';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';

export const authRoutes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: RoutePaths.Login,
    component: LoginComponent,
  },
  {
    path: RoutePaths.NotAllowed,
    component: NotAllowedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
