import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import { ConfigService } from 'src/app/shared/services/config.service';

export interface CalendarInput {
  pUserId: string;
  pInicio: string;
  pFin: string;
}
export interface ResponseCall {
  '@odata.context': string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class ListVacationsCall {
  private readonly _getListVacations =
    this._configService.url('urlListVacations');

  public constructor(
    private readonly _authStorageService: AuthStorageService,
    private readonly _http: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  public getVacations(calendarInput: CalendarInput): Observable<ResponseCall> {
    const bodyPost = {
      pParam:
        '{"pUserId": "' +
        calendarInput.pUserId +
        '","pInicio": "' +
        calendarInput.pInicio +
        '","pFin": "' +
        calendarInput.pFin +
        '"}',
    };
    const option = {
      Authorization:
        this._authStorageService.getDataSaved().b64KeyAuth.Authorization,
    };
    return this._http.post<ResponseCall>(this._getListVacations, bodyPost, {
      headers: option,
    });
  }
}
