import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import {
  CELL_TYPE,
  EmployeePortalStorageService,
  Recognitions,
} from 'src/app/employee-portal/employee-portal.storage.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import {
  CalendarInput,
  RecognitionsCall,
  ResponseCall,
} from './recognitions.call';
import { TABLE_HEADERS_RECOGNITIONS } from './recognitions.constants';

@Component({
  templateUrl: './recognitions.component.html',
  styleUrls: ['./recognitions.component.scss'],
})
export class RecognitionsComponent implements OnInit {
  constructor(
    private readonly _employeePortalStorageService: EmployeePortalStorageService,
    private readonly _recognitionsCall: RecognitionsCall,
    private readonly _authStorageService: AuthStorageService,
    private readonly _errorHandler: ErrorHandlerService
  ) {}

  private _recognitions: Recognitions[];
  public columns = TABLE_HEADERS_RECOGNITIONS;

  public today: Date = new Date();
  public startDate: Date;
  public spinner = false;
  public year = new FormControl();
  public recognitions = [];
  public cellType = CELL_TYPE;

  private _dateRequest: string;

  public get dateToShow(): string {
    if (!this._dateRequest) {
      return '';
    }
    return this._dateRequest;
  }

  public ngOnInit(): void {
    this._dateRequest = `Año ${this.today.getFullYear()}`;
    this.makeTableValues();
  }

  public updateRecognitions(yearSelected: number): void {
    this.spinner = true;
    const dateStart: Date = new Date(yearSelected, 0, 2);
    const dateEnd: Date = new Date(yearSelected, 11, 32);
    const calendarInput: CalendarInput = {
      pInicio: dateStart.toISOString(),
      pFin: dateEnd.toISOString(),
      pUserId: this._authStorageService.getDataSaved().pUserId,
    };
    this._recognitionsCall
      .getRecognitions(calendarInput)
      .pipe(
        tap((arrayRecognitions: ResponseCall) => {
          this._employeePortalStorageService.recognitions =
            arrayRecognitions.value;
          this.makeTableValues();
        }),
        catchError((err) =>
          throwError(this._errorHandler.error(err.error?.error?.message))
        ),
        finalize(() => (this.spinner = false))
      )
      .subscribe();
  }
  private makeTableValues(): void {
    this.recognitions = [];
    this._recognitions = JSON.parse(
      this._employeePortalStorageService.recognitions
    );
    for (const recognition of Object.values(this._recognitions)) {
      const mtr = {
        'Centro Medico Nombre': recognition['Centro Medico Nombre'].value,
        'Centro Medico': recognition['Centro Medico'].value,
        'Enlace Web Resultados': recognition['Enlace Web Resultados'].value,
        'Fecha Apto Condicionado': this.formatStringDate(
          recognition['Fecha Apto Condicionado'].value
        ),
        'Fecha Recepcion Docs': this.formatStringDate(
          recognition['Fecha Recepcion Docs'].value
        ),
        'Fecha Reconocimiento': this.formatStringDate(
          recognition['Fecha Reconocimiento'].value
        ),
        'Fecha Renuncia': this.formatStringDate(
          recognition['Fecha Renuncia'].value
        ),
        'Fecha Vto': this.formatStringDate(recognition['Fecha Vto'].value),
        'Producto Descrripcion': recognition['Producto Descrripcion'].value,
        'Recurso Nombre': recognition['Recurso Nombre'].value,
        Característica: recognition.Característica.value,
        Comentarios: recognition.Comentarios.value,
        Frecuencia: recognition.Frecuencia.value,
        Producto: recognition.Producto.value,
        Resultado: recognition.Resultado.value,
        Recurso: recognition.Recurso.value,
      };
      this.recognitions.push(mtr);
    }
  }

  public chosenYearHandler(
    normalizedMonth: Date,
    datepicker: MatDatepicker<Date>
  ): void {
    this._dateRequest = `Año ${normalizedMonth.getFullYear()}`;
    datepicker.close();
    this.updateRecognitions(normalizedMonth.getFullYear());
  }

  public formatStringDate(dateString: string): string {
    if (dateString) {
      const stringDateStart = dateString.split('/');
      const dateStart =
        '20' +
        stringDateStart[2] +
        '-' +
        stringDateStart[1] +
        '-' +
        stringDateStart[0];
      return dateStart;
    }
    return '';
  }
}
