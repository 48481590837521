<h1 mat-dialog-title>
  {{data.headerMessage}}
</h1>
<div mat-dialog-content>
  <ng-container *ngFor="let key of data.keys; let i = index">
    <p *ngIf="isDateFormat(key); else noDateTemplate">{{data.labels[i]}}: {{data.item[key] | date: 'dd/MM/yyyy'}}</p>
    <ng-template #noDateTemplate>
      <p>{{data.labels[i]}}: {{data.item[key]}}</p>
    </ng-template>
  </ng-container>
</div>
<div class=" buttons-section" mat-dialog-actions>
  <a class="cancel-button" ekibaButton ekibaButtonType="secondary" (click)="closeDialog()">
    Cancelar
  </a>
  <a ekibaButton ekibaButtonType="delete" (click)="onSubmit()">
    Eliminar
  </a>
</div>
