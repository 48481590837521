import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import {
  EmployeePortalStorageService,
  Vacations,
} from '../../employee-portal.storage.service';
import { RequestVacationsComponent } from '../vacations/request-vacations/request-vacations.component';
import {
  CalendarInput,
  ResponseCall,
  WorkCalendarCall,
} from './work-calendar.call';

@Component({
  templateUrl: './work-calendar.component.html',
  styleUrls: ['./work-calendar.component.scss'],
})
export class WorkCalendarComponent implements OnInit {
  constructor(
    private readonly _employeePortalStorageService: EmployeePortalStorageService,
    private readonly _workCalendarCall: WorkCalendarCall,
    private readonly _authStorageService: AuthStorageService,
    private readonly _dialog: MatDialog,
    private readonly _errorHandler: ErrorHandlerService
  ) {}
  public today: Date = new Date();
  public startDate: Date;
  public datepipe: DatePipe;
  public MONTHS: string[] = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE',
  ];
  public arrayOfDaysFilter = [];
  public arrayOfColorusFilter = [];
  public spinner = false;
  public objHolidays = [];
  public objWorkDays = [];
  public dateClass: MatCalendarCellClassFunction<Date>;
  public myFilter;
  public arrayDates: Array<Date> = [];
  public updateCalendarDates = false;
  private _dateHolidays;
  private _arrayVacations: Vacations[];
  private _workDays;

  public ngOnInit(): void {
    this.addFiltersAndClass();
    this.createCaledarArray();
  }

  private addFiltersAndClass(): void {
    this._dateHolidays = this._employeePortalStorageService.holidais;
    this._arrayVacations = this._employeePortalStorageService.vacations;
    this._workDays = this._employeePortalStorageService.workDays;
    const arraYKeys = Object.keys(this._dateHolidays);
    const arrayValues = Object.values(this._dateHolidays);
    for (let i = 0; i < arrayValues.length; i++) {
      if (arrayValues[i]) {
        this.objHolidays.push(arraYKeys[i]);
      }
    }

    const arrayKeysWorkDays = Object.keys(this._workDays);
    const arrayValuesWorkDays = Object.values(this._workDays);
    for (let i = 0; i < arrayValuesWorkDays.length; i++) {
      if (arrayValuesWorkDays[i]) {
        this.objWorkDays.push(arrayKeysWorkDays[i]);
      }
    }
    this.dateClass = (cellDate, view) => {
      if (view === 'month') {
        const date =
          cellDate.getDate() < 10
            ? '0' + cellDate.getDate()
            : cellDate.getDate();
        const month =
          cellDate.getMonth() < 9
            ? '0' + (cellDate.getMonth() + 1)
            : cellDate.getMonth() + 1;
        const stringDate =
          date + '/' + month + '/' + cellDate.getFullYear().toString().slice(2);
        if (Object.keys(this._dateHolidays).includes(stringDate)) {
          return 'holiday-day';
        } else {
          for (let i = 0; i < this._arrayVacations.length; i++) {
            const stringDateStart =
              this._arrayVacations[i]['Starting Date'].value.split('/');
            const stringDateEnd =
              this._arrayVacations[i]['Ending Date'].value.split('/');
            const dateStart = new Date(
              parseInt('20' + stringDateStart[2], 10),
              parseInt(stringDateStart[1], 10) - 1,
              parseInt(stringDateStart[0], 10)
            );
            const dateEnd = new Date(
              parseInt('20' + stringDateEnd[2], 10),
              parseInt(stringDateEnd[1], 10) - 1,
              parseInt(stringDateEnd[0], 10)
            );
            if (cellDate >= dateStart && cellDate <= dateEnd) {
              return 'vacation-day';
            }
          }
          if (!this.objWorkDays.includes(stringDate)) {
            return 'work-day';
          }
        }
      }
      return 'free-day';
    };
    this.myFilter = (d: Date | null): boolean => {
      const day = (d || new Date()).getDay();
      this.arrayOfDaysFilter.push(day !== 0 && day !== 6);
      return day !== 0 && day !== 6;
    };
  }

  private updateCalendarCalls(): Observable<
    [ResponseCall, ResponseCall, ResponseCall]
  > {
    const dateStart: Date = new Date(this.today.getFullYear(), 0, 2);
    const dateEnd: Date = new Date(this.today.getFullYear(), 11, 32);
    const calendarInput: CalendarInput = {
      pInicio: dateStart.toISOString(),
      pFin: dateEnd.toISOString(),
      pUserId: this._authStorageService.getDataSaved().pUserId,
    };

    const Vacations$ = this._workCalendarCall
      .getVacationsCalendar(calendarInput)
      .pipe(
        tap(
          (arrayVacations: ResponseCall) =>
            (this._employeePortalStorageService.vacations = Object.values(
              JSON.parse(arrayVacations.value)
            ))
        ),
        catchError((err) =>
          throwError(this._errorHandler.error(err.error?.error?.message))
        )
      );

    const Holidays$ = this._workCalendarCall
      .getHolidaysCalendar(calendarInput)
      .pipe(
        tap(
          (arrayDates: ResponseCall) =>
            (this._employeePortalStorageService.holidais = JSON.parse(
              arrayDates.value
            ))
        ),
        catchError((err) =>
          throwError(this._errorHandler.error(err.error?.error?.message))
        )
      );

    calendarInput.pTipoCalendario = '0';
    const WorkDays$ = this._workCalendarCall
      .getWorkCalendar(calendarInput)
      .pipe(
        tap(
          (arrayDates: ResponseCall) =>
            (this._employeePortalStorageService.workDays = JSON.parse(
              arrayDates.value
            ))
        ),
        catchError((err) =>
          throwError(this._errorHandler.error(err.error?.error?.message))
        )
      );

    return forkJoin([Vacations$, Holidays$, WorkDays$]).pipe(
      finalize(() => {
        this.spinner = false;
      })
    );
  }

  private createCaledarArray(): void {
    this.arrayDates = [];
    for (let x = 0; x < 12; x++) {
      this.arrayDates.push(new Date(this.today.getFullYear(), x, 1));
    }
  }

  public updateCalendar(yearSelected: number): void {
    this.spinner = true;
    this.today = new Date(yearSelected, 0, 1);
    this.updateCalendarCalls()
      .pipe(
        finalize(() => {
          this.addFiltersAndClass();
        })
      )
      .subscribe();
    this.createCaledarArray();
  }

  public requestVacation(): void {
    this._dialog.open(RequestVacationsComponent, {
      height: 'auto',
      width: 'auto',
      ariaLabel: 'TItulo',
      panelClass: 'request-vacation-popup',
      data: { runInModal: true },
    });
  }
}
