<router-outlet *ngIf="isLoginSection(); else elseTemplate"></router-outlet>
<ng-template #elseTemplate>
  <ngx-loading [show]="isLoading" [config]="{ fullScreenBackdrop: true }">
  </ngx-loading>
  <ekiba-navbar
    *ngIf="!isLoading"
    [menuItems]="fullNavbar"
    [homeRoute]="homeRoute"
    [name]="_authStorageService.getUserName()"
    (requestLogout)="logOut()"
  >
    >
  </ekiba-navbar>
</ng-template>
