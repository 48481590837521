<div class="work-calendar-container">
  <mat-card-title>Calendario Laboral</mat-card-title>
  <hr class="hr-title">
  <mat-card-subtitle>En éste calendario podrás consultar los días festivos y los que has solicitado vacaciones.
  </mat-card-subtitle>
  <br>
  <div class="button-raised-div">
    <a class="year-paginator">{{today.getFullYear()}}</a>
    <a #upYear class="button-paginator" (click)="updateCalendar(today.getFullYear()-1)"><mat-icon>keyboard_arrow_left</mat-icon></a>
    <a #downYear class="button-paginator" (click)="updateCalendar(today.getFullYear()+1)"><mat-icon>keyboard_arrow_right</mat-icon></a>
  </div>
  <div class="leyend-colors-calendar">
    <span class="dot pink"></span>
    <a>Festivo</a>
    <span class="dot green"></span>
    <a>Vacaciones</a>
    <span class="dot blue"></span>
    <a>Sin capacidad</a>
  </div>
  <div class="button-raised-div-responsive">
    <a #upYear class="button-paginator" (click)="updateCalendar(today.getFullYear()-1)"><mat-icon>keyboard_arrow_left</mat-icon></a>
    <a class="year-paginator">{{today.getFullYear()}}</a>
    <a #downYear class="button-paginator" (click)="updateCalendar(today.getFullYear()+1)"><mat-icon>keyboard_arrow_right</mat-icon></a>
  </div>
  <ng-container [ngSwitch]="spinner">
    <mat-spinner class="spinner" *ngSwitchCase="true"></mat-spinner>
    <div *ngSwitchDefault class="cards-calendars-container">
      <mat-card class="demo-inline-calendar-card" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11]">
        <div class="month-header">
          <a>{{MONTHS[i]}}</a>
        </div>
        <mat-calendar #matCalendar [dateFilter]="myFilter" [startAt]="arrayDates[i]" [dateClass]="dateClass"></mat-calendar>
      </mat-card>
    </div>
  </ng-container>
  <button mat-raised-button color="accent" class="button-vacations" (click)="requestVacation()">Solicitar
    Vacaciones</button>
</div>