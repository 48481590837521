<h1 mat-dialog-title>Listado Cód. Ausencias</h1>
<div mat-dialog-content>
  <mat-list>
    <ng-container *ngFor="let cod of codAusencias; let i = index">
      <mat-list-item
        class="ausencia"
        [class.selected]="selectedAusencia === cod"
        (click)="selectedAusencia = cod"
      >
        {{ cod }}
      </mat-list-item>
      <hr />
    </ng-container>
  </mat-list>
</div>
<div class="buttons-section" mat-dialog-actions>
  <a
    class="cancel-button"
    mat-raised-button
    color="disabled"
    (click)="closeDialog()"
  >
    Cancelar
  </a>
  <a
    mat-raised-button
    color="accent"
    class="confirm-button"
    (click)="onSubmit()"
  >
    Confirmar
  </a>
</div>
