import { Component } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { ContactCall } from './contact.call';

@Component({
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  constructor(
    private readonly _contactCall: ContactCall,
    private readonly _errorHandler: ErrorHandlerService
  ) {}
  public observations = '';

  public sendEmail(): void {
    this._contactCall
      .sendMail(this.observations)
      .pipe(
        catchError((err) =>
          throwError(this._errorHandler.error(err.error?.error?.message))
        )
      )
      .subscribe();
  }
}
