export const enum Domains {
  Auth = 'authorizations/',
  Login = 'login/',
}

/**
 * AUTH ROUTES EXPOSED TO HIDE THE NAVBAR WHEN NEEDED
 */
export const AUTH_ROUTES = [
  '/login',
  '/pagina-no-encontrada',
  '/no-autorizado',
];

export const enum RoutePaths {
  Login = 'login',
  NotFound = 'pagina-no-encontrada',
  NotAllowed = 'no-autorizado',
  // Hardcoded entry-point to the app. Type here your first route from your app
  Home = 'inicio',
}
