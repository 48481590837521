import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EMPLOYEE_PORTAL_DEFAULT_ROUTE} from './employee-portal/employee-portal-routing.module';
import {EmployeePortalModule} from './employee-portal/employee-portal.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: EMPLOYEE_PORTAL_DEFAULT_ROUTE,
    loadChildren: () => import('./employee-portal/employee-portal.module').then(m => m.EmployeePortalModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], exports: [RouterModule, EmployeePortalModule]})
export class AppRoutingModule {
}
