<ng-container class="container-hours-booked">
  <div class="hours-graph-container">
    <mat-card-title>Vacaciones - Añadir Solicitud
      <a *ngIf="runInModal" type="button" (click)="closeModal()" class="close" data-dismiss="modal"><mat-icon>close_2</mat-icon></a>
    </mat-card-title>
    <hr class="hr-title">
    <mat-card-subtitle>Rellena el siguiente formulario para solicitar vacaciones
    </mat-card-subtitle>
    <br>
    <mat-card class="card-vacations-request">
      <mat-card class="card-calendar">
        <mat-calendar #calendarRange class="calendar-range" [(selected)]="rangeDates"
          [comparisonStart]="rangeDates.start" [comparisonStart]="rangeDates.end" (selectedChange)="onChange($event)">
        </mat-calendar>
      </mat-card>
      <div class="div-inputs-form">
        <mat-form-field appearance="fill">
          <mat-label>Rango de Fechas</mat-label>
          <mat-icon matSuffix>today</mat-icon>
          <input *ngIf="endDate" matInput disabled value="{{startDate}} - {{endDate}}">
          <input *ngIf="!endDate" matInput disabled value="{{startDate}}">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Dias Habiles</mat-label>
          <mat-icon matSuffix>today</mat-icon>
          <input matInput disabled value="{{daysOfWork}}">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Descripción</mat-label>
          <input [formControl]="descriptionFormControl" matInput>
          <mat-icon matSuffix>mode_edit</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Estado</mat-label>
          <mat-icon matSuffix>error</mat-icon>
          <input matInput disabled value="Pendiente de aprobacion">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Fecha de solicitud</mat-label>
          <mat-icon matSuffix>today</mat-icon>
          <input matInput disabled value="{{todayDateFormat}}">
        </mat-form-field>
        <ng-container [ngSwitch]="showRequestButton">
          <button mat-raised-button *ngSwitchCase="true" (click)="requestVacations()" class="button-vacations">Solicitar
            Vacaciones</button>
          <button mat-raised-button *ngSwitchDefault disabled (click)="requestVacations()"
            class="button-vacations-disabled">Solicitar Vacaciones</button>
        </ng-container>
      </div>
    </mat-card>
  </div>
</ng-container>