<div class="navbar-container" [class.isMobile]="mobileQuery.matches">
  <mat-toolbar class="toolbar styled-navbar">
    <mat-toolbar-row>
      <div class="container-top-navbar">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon class="left-icon-white">menu</mat-icon>
        </button>
        <img
          (click)="redirectTo([homeRoute])"
          src="../../../../assets/img/logo.png"
          alt="Iml"
          class="brand-icon"
        />
        <h1 class="title-menu">Portal del Empleado</h1>
        <span class="filler"></span>
        <ul class="navigation-items">
          <li>
            <div class="link-left-icon">
              <button
                mat-icon-button
                class="link-left-icon"
                (click)="redirectTo([homeRoute])"
              >
                <mat-icon class="left-icon">home</mat-icon>
                <a id="text-icon">Inicio</a>
              </button>
            </div>
          </li>
          <li>
            <div class="link-left-icon">
              <button mat-icon-button class="link-left-icon" (click)="logOut()">
                <mat-icon class="left-icon">logout</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="0">
    <mat-sidenav
      #sidenav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [style.width.px]="224"
      [fixedInViewport]="mobileQuery.matches"
      [opened]="!mobileQuery.matches"
      fixedTopGap="56"
    >
      <mat-nav-list>
        <mat-accordion>
          <ng-container *ngFor="let item of menuItems">
            <ng-container [ngSwitch]="item.menuItems.length !== 0">
              <mat-expansion-panel *ngSwitchCase="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon
                      class="item-icon"
                      (click)="redirectTo(item.routes)"
                      >{{ item.icon }}</mat-icon
                    >
                    <span class="item-label"> {{ item.label }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-expansion-panel-body>
                  <mat-nav-list
                    class="dashboard-nav"
                    *ngFor="let subRoute of item.menuItems"
                  >
                    <a
                      [ngClass]="{ active: subRoute.clicked }"
                      mat-list-item
                      [routerLink]="getRoutes(subRoute)"
                    >
                      <span class="dashboard-child">
                        {{ subRoute.label }}
                      </span>
                    </a>
                  </mat-nav-list>
                </mat-expansion-panel-body>
              </mat-expansion-panel>
              <mat-expansion-panel *ngSwitchDefault hideToggle>
                <mat-expansion-panel-header (click)="redirectTo(item.routes)">
                  <mat-panel-title>
                    <mat-icon class="item-icon">{{ item.icon }}</mat-icon>
                    <span class="item-label"> {{ item.label }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
            </ng-container>
          </ng-container>
        </mat-accordion>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="container-snavbar">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <mat-sidenav-container class="sidenav-container-end">
    <a>{{ configService.get("footer") }}</a>
  </mat-sidenav-container>
</div>
