import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { ContactComponent } from './dashboards/contact/contact.component';

import { CoursesComponent } from './dashboards/courses/courses.component';
import { MaterialsComponent } from './dashboards/material/material.component';
import { HoursBookedComponent } from './dashboards/presence/hours-booked/hours-booked.component';
import { ListAbsencesComponent } from './dashboards/presence/list-absences/list-absences.component';
import { RecognitionsComponent } from './dashboards/recognitions/recognitions.component';
import { ListVacationsComponent } from './dashboards/vacations/list-vacations/list-vacations.component';
import { RequestVacationsComponent } from './dashboards/vacations/request-vacations/request-vacations.component';
import { WorkCalendarComponent } from './dashboards/work-calendar/work-calendar.component';
import { EmployeePortalRoutingModule } from './employee-portal-routing.module';
import { HomeComponent } from './home/home.component';
import { FichajeComponent } from './dashboards/fichaje/fichaje.component';
import { CodAusenciasListComponent } from './dashboards/fichaje/cod-ausencias-list/cod-ausencias-list.component';
import { ListFichajeComponent } from './dashboards/fichaje/list-fichaje/list-fichaje.component';

@NgModule({
  declarations: [
    CoursesComponent,
    HomeComponent,
    HoursBookedComponent,
    ListAbsencesComponent,
    ListVacationsComponent,
    MaterialsComponent,
    RecognitionsComponent,
    RequestVacationsComponent,
    WorkCalendarComponent,
    ContactComponent,
    FichajeComponent,
    CodAusenciasListComponent,
    ListFichajeComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // Author
    SharedModule,
    EmployeePortalRoutingModule,
  ],
  providers: [],
})
export class EmployeePortalModule {}
