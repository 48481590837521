import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { StatusColorValue } from 'ekiba-master-table';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import { SVacations } from 'src/app/employee-portal/employee-portal.storage.service';
import { EmployeePortalStorageService } from 'src/app/employee-portal/employee-portal.storage.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { RequestVacationsComponent } from '../request-vacations/request-vacations.component';
import {
  CalendarInput,
  ListVacationsCall,
  ResponseCall,
} from './list-vacations.call';
import { CELL_TYPE, TABLE_HEADERS_VACATIONS } from './list-vacations.constants';

@Component({
  templateUrl: './list-vacations.component.html',
  styleUrls: ['./list-vacations.component.scss'],
})
export class ListVacationsComponent implements OnInit {
  constructor(
    private readonly _employeePortalStorageService: EmployeePortalStorageService,
    private readonly _listVacationsCall: ListVacationsCall,
    private readonly _authStorageService: AuthStorageService,
    private readonly _dialog: MatDialog,
    private readonly _errorHandler: ErrorHandlerService
  ) {}

  private _arrayVacations: SVacations[];
  public columns = TABLE_HEADERS_VACATIONS;
  public CELL_TYPE = CELL_TYPE;

  public today: Date = new Date();
  public startDate: Date;
  public spinner = false;
  public year = new FormControl();
  public vacations = [];
  public statusColorsValues: StatusColorValue[] = [
    { class: 'green-line', linkedValue: 'Consumida' },
    { class: 'grey-line', linkedValue: 'Pendiente estudio' },
    { class: 'blue-line', linkedValue: 'Confirmada' },
    { class: 'red-line', linkedValue: 'Rechazada' },
  ];
  private _dateRequest: string;
  private _needUpdate = false;

  public get dateToShow(): string {
    if (!this._dateRequest) {
      return '';
    }
    return this._dateRequest;
  }

  public ngOnInit(): void {
    this._dateRequest = `Año ${this.today.getFullYear()}`;
    this.makeTableValues();
  }

  public updateVacations(yearSelected: number): void {
    if (this._needUpdate) {
      this.spinner = true;
      const dateStart: Date = new Date(yearSelected, 0, 2);
      const dateEnd: Date = new Date(yearSelected, 11, 32);
      const calendarInput: CalendarInput = {
        pInicio: dateStart.toISOString(),
        pFin: dateEnd.toISOString(),
        pUserId: this._authStorageService.getDataSaved().pUserId,
      };
      this._listVacationsCall
        .getVacations(calendarInput)
        .pipe(
          tap((arrayVacations: ResponseCall) => {
            this._employeePortalStorageService.Svacations = Object.values(
              JSON.parse(arrayVacations.value)
            );
            this.makeTableValues();
          }),
          catchError((err) =>
            throwError(this._errorHandler.error(err.error?.error?.message))
          ),
          finalize(() => (this.spinner = false))
        )
        .subscribe();
    }
    this._needUpdate = false;
  }
  private makeTableValues(): void {
    this.vacations = [];
    this._arrayVacations = this._employeePortalStorageService.Svacations;
    for (const vacation of Object.values(this._arrayVacations)) {
      const abs = {
        'Ending Date': this.formatStringDate(vacation['Ending Date'].value),
        'Starting Date': this.formatStringDate(vacation['Starting Date'].value),
        'Fecha Solicitud': this.formatStringDate(
          vacation['Fecha Solicitud'].value
        ),
        'Motivo Cambio Estado': vacation['Motivo Cambio Estado'].value,
        'Nombre Recurso': vacation['Nombre Recurso'].value,
        'Num Dias Nat Hab': vacation['Num Dias Nat Hab'].value,
        'Resource Group No': vacation['Resource Group No'].value,
        Description: vacation.Description.value,
        Estado: vacation.Estado.value,
        IdUnico: vacation.IdUnico.value,
        Recurso: vacation.Recurso.value,
      };
      this.vacations.push(abs);
    }
  }

  public chosenYearHandler(
    normalizedMonth: Date,
    datepicker: MatDatepicker<Date>
  ): void {
    this._dateRequest = `Año ${normalizedMonth.getFullYear()}`;
    datepicker.close();
    this._needUpdate = true;
    this.updateVacations(normalizedMonth.getFullYear());
  }

  public formatStringDate(dateString: string): string {
    const stringDateStart = dateString.split('/');
    const dateStart =
      '20' +
      stringDateStart[2] +
      '-' +
      stringDateStart[1] +
      '-' +
      stringDateStart[0];
    return dateStart;
  }

  public requestVacation(): void {
    this._dialog.open(RequestVacationsComponent, {
      height: 'auto',
      width: 'auto',
      ariaLabel: 'TItulo',
      panelClass: 'request-vacation-popup',
      data: { runInModal: true },
    });
  }
}
