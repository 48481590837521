import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {Action, CreatePopupInput} from '../../../../interfaces/popup';

export interface DeletePopupInput extends CreatePopupInput {
  keys: string[];
  labels: string[];
  item: any;
  headerMessage: string;
  action: Action;
}

export interface DeletePopupOutput {
  event: string;
}

const DATE_KEYS_LIST = [
  'datetime_add',
  'datetime_del',
  'datetime_end',
  'datetime_start',
  'datetime_upd',
  'fecha_fin',
  'fecha_inicio',
  'fecha',
];

@Component({
  templateUrl: './delete-table-popup.component.html',
  styleUrls: ['../../../../../styles/pop-up.form.styles.scss'],
})
export class DeleteTablePopupComponent {
  public cancelled = false;

  constructor(
      public dialogRef: MatDialogRef<DeleteTablePopupComponent>,
      // @Optional() is used to prevent error if no data is passed
      @Optional() @Inject(MAT_DIALOG_DATA) public data: DeletePopupInput,
  ) {
    dialogRef.disableClose = true;
  }

  public onSubmit(): void {
    if (!this.cancelled) {
      this._confirmDeletion();
    }
  }

  public closeDialog(): void {
    this.cancelled = true;
    this.dialogRef.close({event: 'Cancelar'});
  }

  public isDateFormat(key: string): boolean {
    return DATE_KEYS_LIST.includes(key);
  }

  private _confirmDeletion(): void {
    const deleteTablePopupOutput: DeletePopupOutput = {
      event: Action.delete,
    };
    this.dialogRef.close(deleteTablePopupOutput);
  }
}
