import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ekiba-mat-card',
  templateUrl: './ekiba-mat-card.component.html',
  styleUrls: ['./ekiba-mat-card.component.scss'],
})
export class EkibaMatCardComponent implements OnInit {
  /**
   * Title displayed on the card
   */
  @Input()
  public title: string;

  public constructor() {}

  public ngOnInit(): void {
  }
}
