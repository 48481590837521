import {ColumnTypes, TableHeaders} from 'ekiba-master-table';

export const TABLE_HEADERS_ABSENCES: TableHeaders[] = [
  {
    name: 'Recurso',
    displayName: 'Recursos',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Tipo',
    displayName: 'Tipos',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Starting Date',
    displayName: 'Fecha Inicial',
    type: ColumnTypes.Date,
  },
  {
    name: 'Ending Date',
    displayName: 'Fecha Final',
    type: ColumnTypes.Date,
  },
  {
    name: 'Num Dias Nat Hab',
    displayName: 'Dias Hábiles',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Description',
    displayName: 'Descripción',
    type: ColumnTypes.Standard,
  },
];
