// TODO IT MIGHT BE USEFUL IN THE FUTURE

import {Component} from '@angular/core';
import {
  FormGroup,
} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

export interface SelectedDates {
  datetime_start: string;
  datetime_end: string;
}

export interface PickDatePopupOutput {
  event: string;
  data: SelectedDates;
}

@Component({
  templateUrl: './pick-date.component.html',
  styleUrls: ['../../../../../styles/pop-up.form.styles.scss'],
})
export class PickDatePopupComponent {
  public pickDateForm: FormGroup;
  public cancelled = false;

  public constructor(
      public dialogRef: MatDialogRef<PickDatePopupComponent>,
  ) {
    dialogRef.disableClose = true;
    this._buildForm();
  }

  public onSubmit(): void {
    if (!this.cancelled) {
      this._saveData();
    }
  }

  public closeDialog(): void {
    this.cancelled = true;
    this.dialogRef.close({event: 'Cancelar'});
  }

  private _buildForm(): void {
    // this.categoryForm = new FormGroup({
    //   datetime_start: new FormControl('', [Validators.required]),
    //   datetime_end: new FormControl('', [Validators.required]),
    // });
  }

  private _saveData(): void {
    // if (this.categoryForm.valid) {
    //   const {datetime_end, datetime_start}: SelectedDates = this.categoryForm.value;
    //   const selectedDates: SelectedDates = {
    //     datetime_start: this._dateManagerService.getDateForDatabase(datetime_start),
    //     datetime_end: this._dateManagerService.getDateForDatabase(datetime_end),
    //   };

    //   const categoryPopupOutput: PickDatePopupOutput = {
    //     event: Action.create,
    //     data: selectedDates,
    //   };
    //   this.dialogRef.close(categoryPopupOutput);
    // }
  }
}
