import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {Action} from '../../../interfaces/popup';
import {
  DeletePopupInput,
  DeletePopupOutput,
  DeleteTablePopupComponent,
} from '../master-table/pop-up/delete-table.popup.component';

export enum ColumnTypes {
  /**
   * To adjust some masks in the output
   * 'date' is piped with date pipe:  {{ value | date: 'dd/MM/yyyy' }}
   * 'standard' won't be piped.
   */
  Date = 'date',
  Standard = 'standard',
}

export interface TableHeaders {
  // Name of the property as on the database
  name: string;
  // Title shown in the table
  displayName: string;
  // Defines some types for piping the output
  type: ColumnTypes;
}

@Component({
  selector: 'ekiba-master-table-dates',
  templateUrl: './master-table-dates.component.html',
  styleUrls: ['./master-table-dates.component.scss'],
})
export class MasterTableDatesComponent implements OnInit, OnDestroy {
  /**
   * Each of the rows, i.e: ["Fire", 1, false];
   * ATENTION: We type 'any' as this table doesn't know the shape of the items
   * that will be recieved.
   *
   * Set is used to synchronize the input received with _updatedSource. Needed for the filters
   */
  @Input()
  public set dataSource(newValue) {
    if (newValue === this._updatedSource || newValue.length === 0) {
      return;
    }
    this._dataSource = new MatTableDataSource(newValue);
    this._updatedSource = newValue;
    this._dataSource.sort = this.sort;

    if (this.showPaginator) {
      this._dataSource.paginator = this.paginator;
    }

    if (this.showFilters && this.tableFormControls) {
      this.tableFormControls.forEach(control => control.reset());
    }
  }
  public get dataSource(): any {
    return this._dataSource;
  }

  /**
   * Each of the attributes that a row can have, i.e: ["Type", "height", ....].
   * They must have been set following the TableHeaders Interface
   */
  @Input()
  public columns: TableHeaders[];

  @Input()
  public showFilters = false;

  @Input()
  public showPaginator = false;

  @Input()
  public showDisableButton = true;

  @Output()
  public changedDates = new EventEmitter<any>();

  @Output()
  public deletedRow = new EventEmitter<any>();

  @Output()
  public enableDisabledRow = new EventEmitter<any>();

  @Output()
  public updatedRow = new EventEmitter<any>();

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  public columnLabels: string[] = ['action'];
  public columnTypes = ColumnTypes;
  public headersFilters: TableHeaders[];
  public headersFilterName: string[];

  // Inputs for the filters
  public tableFormControls: FormControl[];

  // Source displayed on the table, it can be modified by the internal filters
  public _dataSource;

  private _destroyed$ = new Subject();
  private _recievedLabels: string[];

  // Static source within the component
  private _updatedSource;

  // Object that'll have the filters
  private _filterValues = {};

  public constructor(
      private readonly _dialog: MatDialog,
      private paginators: MatPaginatorIntl,
  ) {
    this.paginators.itemsPerPageLabel = 'Items por página:';
    this.paginators.nextPageLabel = 'Página siguiente';
    this.paginators.previousPageLabel = 'Página anterior';
    this.paginators.firstPageLabel = 'Primera página';
    this.paginators.lastPageLabel = 'Última página';
    this.paginators.getRangeLabel = this.getRangeLabel;
  }

  public ngOnInit(): void {
    this._recievedLabels = this.columns.map((column: TableHeaders) => column.name);
    this.headersFilters = this.columns.map((column: TableHeaders) => column);
    this.headersFilters.push({displayName: 'workAround', name: 's', type: ColumnTypes.Standard});
    this.headersFilterName = this.columns.map((column: TableHeaders) => column.displayName).concat('workAround');
    this.columnLabels = this._recievedLabels.concat(this.columnLabels);
    if (this.showFilters) {
      this._setFilters();
    }
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public deleteRow(row: any): void {
    const deleteRowInput: DeletePopupInput = {
      action: Action.delete,
      headerMessage: '¿Está seguro de que desea eliminar este registro?',
      item: row,
      keys: this._recievedLabels,
      labels: this.columns.map((column: TableHeaders) => column.displayName),
    };

    const dialogRef = this._dialog.open(DeleteTablePopupComponent, {
      width: '500px',
      data: deleteRowInput,
    });

    dialogRef.afterClosed().pipe(filter((result: DeletePopupOutput) => result.event === Action.delete)).subscribe(() => {
      this.deletedRow.emit(row);
    });
  }

  public enableDisableRow(row: any): void {
    this.enableDisabledRow.emit(row);
  }

  public updateRow(row: any): void {
    this.updatedRow.emit(row);
  }

  public changeDates(row: any): void {
    this.changedDates.emit(row);
  }

  private _setFilters(): void {
    this._filterValues = this._recievedLabels.reduce((obj, key) => Object.assign(obj, {[key]: ''}), {});
    this._initFormControls();
  }

  private _initFormControls(): void {
    this.tableFormControls = this._recievedLabels.map(() => new FormControl(''));
    this.tableFormControls.forEach(
        (control: FormControl, index) =>
            control.valueChanges.pipe(takeUntil(this._destroyed$), filter(changedValue => changedValue !== null))
                .subscribe((changedValue: string) => {
                  this._filterValues[this._recievedLabels[index]] = changedValue;
                  this._dataSource = this._updatedSource.filter((element) => {
                    for (const key of this._recievedLabels) {
                      if ((!element[key] && key === this._recievedLabels[index]) ||
                          (element[key] &&
                           element[key].toString().toLowerCase().indexOf(this._filterValues[key].toString().toLowerCase())) === -1) {
                        return false;
                      }
                    }
                    return true;
                  });
                  this._dataSource = new MatTableDataSource(this._dataSource);
                  this._dataSource.sort = this.sort;
                  this._dataSource.paginator = this.paginator;
                }));
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }
}
