<ng-container class="container-hours-booked" [ngSwitch]="spinner">
  <div class="hours-graph-container">
    <mat-card-title>Vacaciones - Listado Vacaciones</mat-card-title>
    <hr class="hr-title">
    <mat-card-subtitle>Listado con las fechas relaccionadas con las vacaciones
    </mat-card-subtitle>
    <br>
    <mat-form-field class="mat-form-dateYear" appearance="outline">
      <mat-icon matPreffix>today</mat-icon>
      <input class="input-year" matInput readonly [matDatepicker]="picker" [formControl]="year">
      <span class="year-span">{{dateToShow}}</span>
      <mat-datepicker-toggle class="datepiker-toggle-icon" matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearHandler($event, picker)">
      </mat-datepicker>
    </mat-form-field>
    <mat-spinner class="spinner" *ngSwitchCase="true"></mat-spinner>
    <ekiba-master-table *ngSwitchDefault 
    [dataSource]="vacations" 
    [showDefaultActions]="false" 
    [columns]="columns" 
    [showFilters]="true"
    [statusColorValues]="statusColorsValues" 
    [cellType]="CELL_TYPE">
    </ekiba-master-table>
    <button mat-raised-button color="accent" class="button-vacations" (click)="requestVacation()">Solicitar
      Vacaciones</button>
  </div>
</ng-container>