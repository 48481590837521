import {Pipe, PipeTransform} from '@angular/core';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'Search',
})
export class SearchPipe implements PipeTransform {
  /**
   * @param value: Array of elements
   * @param args: User input
   * @returns filtered array
   */
  public transform(value: any, args: string) {
    if (value === undefined) {
      return;
    }

    if (args === undefined) {
      return value;
    }

    const _args = args.toLowerCase();
    return from(value).pipe(
        map((array: any) => array.filter((matches) => JSON.stringify(matches).toLowerCase().includes(_args))),
    );
  }
}
