import { CellType, ColumnTypes, TableHeaders } from 'ekiba-master-table';

export const TABLE_HEADERS_FICHAJE: TableHeaders[] = [
  {
    name: 'Tipo Movimiento',
    displayName: 'Tipo de Movimiento',
    type: ColumnTypes.Status,
  },
  {
    name: 'Fecha Inicio',
    displayName: 'Fecha Inicial',
    type: ColumnTypes.Date,
  },
  {
    name: 'Fecha Fin',
    displayName: 'Fecha Final',
    type: ColumnTypes.Date,
  },
  {
    name: 'Cdad Horas',
    displayName: 'Cantidad Fichada (horas)',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Description',
    displayName: 'Descripción',
    type: ColumnTypes.Standard,
  },
  {
    name: 'Tipo Incidencia',
    displayName: 'Tipo de Incidencia',
    type: ColumnTypes.Standard,
  },
];

export const CELL_TYPE: CellType = CellType.SmallCell;
