<div class="table_container mat-elevation-z8">
  <table mat-table class="table" [dataSource]="dataSource" matSort>

    <!-- Dynamic content -->
    <ng-container *ngFor="let label of columns" matColumnDef="{{ label.name }}">
      <mat-header-cell class="column" *matHeaderCellDef mat-sort-header>
        {{label.displayName}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <!-- TODO TRY NGSWITCH -->
        <div *ngIf="label.type === columnTypes.Date; else elseTemplate">
          {{ element[label.name] | date: 'dd/MM/yyyy' }}
        </div>
        <ng-template #elseTemplate>
          {{element[label.name]}}
        </ng-template>
      </mat-cell>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="action">
      <mat-header-cell class="columna_final_header" *matHeaderCellDef>Acciones</mat-header-cell>
      <mat-cell *matCellDef="let row" class="columna_final">
        <ng-container [ngSwitch]="row.available">
          <button *ngSwitchCase="true" class="add-button" mat-mini-fab (click)="addRow(row)">
            <mat-icon>
              add
            </mat-icon>
          </button>
          <button *ngSwitchDefault class="remove-button" mat-mini-fab (click)="removeRow(row)">
            <mat-icon class="material-icons">
              remove
            </mat-icon>
          </button>
        </ng-container>

      </mat-cell>
    </ng-container>

    <!-- Filters -->
    <ng-container *ngIf="showFilters">
      <ng-container *ngFor="let headerFilter of headersFilters;let i = index"
        matColumnDef="{{headerFilter.displayName}}">
        <mat-header-cell *matHeaderCellDef>
          <mat-form-field *ngIf="headersFilters.length - 1 !== i && headerFilter.type === columnTypes.Standard"
            class="filter mat-input-search-wrapper" floatLabel="never">
            <input class="wordInput" matInput [formControl]="tableFormControls[i]" autocomplete="off">
          </mat-form-field>
          <!-- TODO-HANDLE-DATE-FILTER -->
          <mat-icon *ngIf="headersFilters.length - 1 !== i  && headerFilter.type === columnTypes.Date"
            class="calendar-icon">
            date_range
          </mat-icon>
        </mat-header-cell>
      </ng-container>
    </ng-container>

    <!-- Content -->
    <mat-header-row *matHeaderRowDef="columnLabels" class="table_header"></mat-header-row>
    <ng-container *ngIf="showFilters">
      <mat-header-row class="no-default-height" *matHeaderRowDef="headersFilterName"></mat-header-row>
    </ng-container>
    <mat-row *matRowDef="let row; columns: columnLabels;"></mat-row>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="5" showFirstLastButtons></mat-paginator>
</div>
<div class="button-section">
  <a class="button-back" ekibaButton ekibaButtonType="secondary" (click)="cancel()">
    Volver
  </a>
  <a class="button-add" ekibaButton ekibaButtonType="primary" (click)="confirm()" >
    Confirmar
  </a>
</div>
