import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {SharedModule} from '../shared/shared.module';
import {AuthRoutingModule} from './auth-routing.module';
import {LoginComponent} from './login/login.component';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UrlInterceptor } from './guards/token.interceptor.service';

@NgModule({
  declarations: [
    LoginComponent,
    NotAllowedComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // Author
    AuthRoutingModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {
}
