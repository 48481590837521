<div *ngIf="show"class="container">
  <img [src]="picture" class="image">
  <div class="info">
    <span class="material-icons">
      {{icon}}
    </span>
    <span class="title">{{title}}</span>
    <span *ngIf="description" class="descritpion">{{description}}</span>
  </div>
</div>
