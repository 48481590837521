<div [ngClass]="isSelectedRow ? 'mat-elevation-selected' : 'mat-elevation-z8'" class="table_container">
  <table mat-table class="table" [dataSource]="dataSource" matSort>

    <!-- Dynamic content -->
    <ng-container *ngFor="let label of columns" matColumnDef="{{ label.name }}">
      <mat-header-cell class="column" *matHeaderCellDef mat-sort-header>
        {{label.displayName}}
      </mat-header-cell>
      <mat-cell [class.selected-cell]="isSelectedRow" *matCellDef="let element">
        <!-- TODO TRY NGSWITCH -->
        <div *ngIf="label.type === columnTypes.Date; else elseTemplate">
          {{ element[label.name] | date: 'dd/MM/yyyy' }}
        </div>
        <ng-template #elseTemplate>
          {{element[label.name]}}
        </ng-template>
      </mat-cell>
    </ng-container>

    <!-- Filters -->
    <ng-container *ngIf="showFilters">
        <ng-container  *ngFor="let headerFilter of headersFilters;let i = index"
          matColumnDef="{{headerFilter.displayName}}">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field *ngIf="headerFilter.type === columnTypes.Standard"
              class="filter mat-input-search-wrapper" floatLabel="never">
              <input class="wordInput" matInput [formControl]="tableFormControls[i]" autocomplete="off">
            </mat-form-field>
            <!-- TODO-HANDLE-DATE-FILTER -->
            <mat-icon *ngIf=" headerFilter.type === columnTypes.Date"
              class="calendar-icon">
              date_range
            </mat-icon>
          </mat-header-cell>
        </ng-container>   
    </ng-container>

    <!-- Content -->
    <mat-header-row *matHeaderRowDef="columnLabels" class="table_header"></mat-header-row>
    <ng-container *ngIf="showFilters">
      <mat-header-row class="no-default-height" *matHeaderRowDef="headersFilterName"></mat-header-row>
    </ng-container>
    <mat-row [ngClass]="isSelectedRow ? 'selected-row' : 'selecting-row'" *matRowDef="let row; columns: columnLabels;" (click)="selectRow(row)"></mat-row>
  </table>
  <mat-paginator [style.display]="isSelectedRow ? 'none' : 'initial'" [pageSizeOptions]="[5, 10, 20]" [pageSize]="inputLines ? inputLines : 5" showFirstLastButtons></mat-paginator>
</div>