import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfigService } from 'src/app/shared/services/config.service';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  public constructor(
    private readonly _configService: ConfigService,
    private readonly _http: HttpClient
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log();
    console.log('UrlInterceptor',req.url, this._configService.proxyEnabled);
    
    if (this._configService.proxyEnabled) {
      req = req.clone({
        url: `https://portalempleado.agenciaekiba.com/bc/proxybc.php?url=${req.url}`,
      });
    } 
    return next.handle(req);
  }

  public configProxy(): void {
    this._http
      .get<any>('assets/bc/configProxy.json')
      .pipe(
        tap((resConfig: any) => {
          console.log(resConfig);
          if (!!resConfig.proxy) {
            this._configService.proxyEnabled = true;
          } else {
            this._configService.proxyEnabled = false;
          }
        })
      )
      .subscribe();
  }
}
