import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthStorageService } from 'src/app/auth/services/auth.storage.service';
import { LoadingOverlayComponent } from 'src/app/shared/components/loading-overlay/loading-overlay.component';
import {
  WarningPopupComponent,
  WarningPopupInput,
} from 'src/app/shared/components/warning/warning-popup.component';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';

import { CalendarInput, RequestVacationsCall } from './request-vacations.call';

@Component({
  templateUrl: './request-vacations.component.html',
  styleUrls: ['./request-vacations.component.scss'],
})
export class RequestVacationsComponent implements OnInit {
  constructor(
    private readonly _requestVacationsCall: RequestVacationsCall,
    private readonly _authStorageService: AuthStorageService,
    private readonly _dialog: MatDialog,
    private readonly _errorHandler: ErrorHandlerService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) {}
  public range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  public descriptionFormControl: FormControl = new FormControl(null);

  public rangeDates: DateRange<Date> | null = null;
  public today = new Date();
  public todayDateFormat = this.today.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  public daysOfWork = 0;
  public startDate = '';
  public endDate = '';
  public runInModal = false;
  private dateEnd: Date;
  private dateStart: Date;
  private countDate = 0;
  private formatter = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  public ngOnInit(): void {
    this.runInModal = this.data?.runInModal;
    this.makeDefaultValues();
  }

  public onChange(date: Date): void {
    if (this.countDate === 0) {
      this.dateStart = date;
      this.daysOfWork = 1;
      this.endDate = '';
      this.dateEnd = null;
      this.startDate = this.formatter.format(date);
      this.countDate = 1;
    } else {
      this.dateEnd = date;
      this.endDate = this.formatter.format(date);
      this.countDate = 0;
    }
    this.refreshDR(
      this.dateStart.toLocaleDateString('en-EN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      this.dateEnd?.toLocaleDateString('en-EN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    );
  }

  public requestVacations(): void {
    const dateStart = new Date(
      this.dateStart.getFullYear(),
      this.dateStart.getMonth(),
      this.dateStart.getDate() + 1
    ).toISOString();
    const dateEnd = this.dateEnd
      ? new Date(
          this.dateEnd?.getFullYear(),
          this.dateEnd?.getMonth(),
          this.dateEnd?.getDate() + 1
        )?.toISOString()
      : undefined;

    const calendarInput: CalendarInput = {
      pInicio: dateStart,
      pFin: dateEnd ? dateEnd : dateStart,
      pUserId: this._authStorageService.getDataSaved().pUserId,
      pDescripcion: this.descriptionFormControl.value
        ? this.descriptionFormControl.value
        : '',
    };
    let data: WarningPopupInput = { headerMessage: '' };
    this._requestVacationsCall
      .addRequestVacations(calendarInput)
      .pipe(
        tap(() => {
          data = {
            headerMessage: '✔️ Solicitud de vacaciones ✔️',
            errorMessage: 'Solicitud de vacaciones realizada correctamente',
          };
        }),
        finalize(() => {
          if (data.headerMessage === '') {
            data = {
              headerMessage: '❌ Error en solicitud de vacaciones ❌',
              errorMessage:
                'Ha ocurrido un error al solicitar las vacaciones seleccionadas. Ya existe un registro de vacaiones para las fechas introducidas',
            };
          }
          this._dialog.open(WarningPopupComponent, {
            width: '800px',
            data,
          });
        })
      )
      .subscribe();
  }

  public get showRequestButton(): boolean {
    return new Date(this.dateStart) > this.today ? true : false;
  }

  private makeDefaultValues(): void {
    this.rangeDates = new DateRange(
      (() => {
        const v = new Date();
        v.setDate(v.getDate());
        return v;
      })(),
      new Date()
    );
  }

  private refreshDR(start: string, end: string): void {
    if (start <= end && this.today <= new Date(start)) {
      const dialog$ = this._dialog.open(LoadingOverlayComponent, {
        height: '100vh',
        width: '100vw',
        maxWidth: '100vw',
        disableClose: true,
        panelClass: 'hide',
      });
      this.rangeDates = new DateRange(new Date(start), new Date(end));
      const dateStart = new Date(
        this.dateStart.getFullYear(),
        this.dateStart.getMonth(),
        this.dateStart.getDate() + 1
      ).toISOString();
      const dateEnd = new Date(
        this.dateEnd.getFullYear(),
        this.dateEnd.getMonth(),
        this.dateEnd.getDate() + 1
      ).toISOString();
      const calendarInput: CalendarInput = {
        pInicio: dateStart,
        pFin: dateEnd,
        pUserId: this._authStorageService.getDataSaved().pUserId,
      };
      this._requestVacationsCall
        .getWorkDays(calendarInput)
        .pipe(
          tap((request) => {
            this.daysOfWork = parseInt(request.value);
            dialog$.close();
          }),
          catchError((err) =>
            throwError(this._errorHandler.error(err.error?.error?.message))
          )
        )
        .subscribe();
    } else if (start !== '' && end !== '' && this.countDate === 0) {
      this.clearValues();
      this.makeDefaultValues();
    }
  }

  private clearValues(): void {
    this.startDate = '';
    this.endDate = '';
    this.daysOfWork = 0;
  }

  public closeModal(): void {
    this._dialog.closeAll();
  }
}
