import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AES } from 'crypto-js';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigService } from 'src/app/shared/services/config.service';

import { AuthStorageService } from './auth.storage.service';

export interface LoginInput {
  pUserId: string;
  pPassword: string;
}

export interface LoginOutput {
  errorMessage?: string;
  value: string;
  pUserId: string;
  b64KeyAuth: KeyHeader;
  EncodeKeyAuth?: string;
  expiryDate?: string;
  permisos?: string[];
  company?: string;
  recursos?: any[];
}

export interface CheckLicenciaInput {
  uuid: string;
  clave: string;
  app?: string;
}

export interface KeyHeader {
  Authorization: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private _loginUrl = this._configService.urlWithoutCompany('urlLogin');
  private readonly _licenciasUrl = this._configService.get('urlLicencias');
  private _pUserID: string;
  private _b64HeaderKey: KeyHeader;

  constructor(
    private readonly _http: HttpClient,
    private readonly _authStorage: AuthStorageService,
    private readonly _configService: ConfigService
  ) {}

  public login(login: LoginInput): Observable<LoginOutput | null> {
    this._pUserID = login.pUserId;
    const b46Key = btoa(
      unescape(
        encodeURIComponent(
          `${this._configService.get('user')}:${this._configService.get(
            'password'
          )}`
        )
      )
    );
    const stringCredentials = {
      pParam:
        '{"pUserId": "' +
        login.pUserId +
        '","pPassword": "' +
        login.pPassword +
        '"}',
    };
    const options = {
      Authorization: `${'Basic ' + b46Key}` as const,
    };
    this._b64HeaderKey = {
      Authorization: options.Authorization,
    };
    this._loginUrl =  this._configService.urlWithoutCompany('urlLogin');
    return this._http
      .post<LoginOutput>(`${this._loginUrl}`, stringCredentials, {
        headers: options,
      })
      .pipe(
        tap((loginOutput: LoginOutput) => {
          loginOutput.b64KeyAuth = options;
          loginOutput.pUserId = login.pUserId;
          loginOutput.company =
            loginOutput.value &&
            JSON.parse(loginOutput.value)?.recursos[0]?.empresa;
          this._authStorage.company = loginOutput.company;
        }),
        catchError((errorResponse) => {
          console.error(errorResponse);
          return of(errorResponse.error);
        })
      );
  }

  public checkLicencia(
    licencia: CheckLicenciaInput
  ): Observable<LoginOutput | null> {
    licencia = { ...licencia, app: this._configService.get('baseAppUrl') };
    return this._http.post<LoginOutput>(`${this._licenciasUrl}`, licencia).pipe(
      catchError((errorResponse) => {
        console.error(errorResponse);
        return of(errorResponse.error);
      })
    );
  }

  public checkLoginUser(loginOutput: LoginOutput): void {
    if (loginOutput?.value !== '{}') {
      const keyJWT =
        `${loginOutput.b64KeyAuth.Authorization}` +
        ';' +
        `${loginOutput.pUserId}` +
        ';' +
        `${new Date().toISOString()}`;
      const keyJWTEncript = AES.encrypt(
        keyJWT,
        this._configService.get('user')
      ).toString();
      loginOutput.EncodeKeyAuth = keyJWTEncript;
      loginOutput.pUserId = this._pUserID;
      loginOutput.b64KeyAuth = this._b64HeaderKey;
      this._authStorage.saveDataLogin(loginOutput);
      console.log('Login code 200');
    } else {
      console.error('Something went wrong');
    }
  }
}
