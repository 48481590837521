<ng-container>
  <div>
    <mat-card-title>Contacto
    </mat-card-title>
    <hr class="hr-title">
    <mat-card-subtitle>Rellena el cuadro de texto para enviar observaciones
    </mat-card-subtitle>
    <br>
    <mat-card class="card-observations">
      <textarea placeholder="Escribe tus observaciones aqui..." name="contenido" rows="15" cols="70"
        [(ngModel)]="observations"></textarea>
      <ng-container [ngSwitch]="observations !== ''">
        <button mat-raised-button *ngSwitchCase="true" (click)="sendEmail()"
          class="button-send">Enviar</button>
        <button mat-raised-button *ngSwitchDefault disabled class="button-send-disabled">Enviar</button>
      </ng-container>
    </mat-card>
  </div>
</ng-container>